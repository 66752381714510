import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, IconButton } from '@material-tailwind/react';
import { PiTrash } from 'react-icons/pi';
import truncate from 'lodash/truncate';
import NoUploads from '../components/NoUploads';
import PlayerMini from '../components/PlayerMini';
import AgamiTypeChip from '../components/AgamiTypeChip';

const TABLE_HEAD = [
  'Name',
  'Description',
  'Created',
  'Audio',
  'Type',
  '',
];

const ExperienceSpotsList = ({
  agamiListLoading,
  agamiList,
  removeFromExperience,
}) => {
  const navigate = useNavigate();

  const remove = async (e, spot) => {
    e.preventDefault();
    e.stopPropagation();
    removeFromExperience(spot);
  };

  return (
    <div className="py-2">

      {!agamiListLoading && (!agamiList || agamiList.length === 0) && (
        <div className="col-span-2">
          <NoUploads>
            <Typography>
              There are no
              {' '}
              <em>agamis</em>
              {' '}
              in this experience yet.
            </Typography>
          </NoUploads>
        </div>
      )}

      {!agamiListLoading && agamiList?.length > 0 && (
        <table className="w-full">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-b border-blue-gray-100 bg-blue-gray-500/30 p-4"
                >
                  <Typography
                    variant="small"
                    color="primary"
                    className="font-bold leading-none"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {agamiList?.map((agami) => (
              <tr
                key={agami.id}
                className="even:bg-blue-gray-50/50 text-center hover:bg-white-yellow cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate(`/spot/${agami.id}`);
                }}
              >
                <td>
                  <Typography
                    title={agami.name}
                    variant="small"
                    className="font-black"
                  >
                    {truncate(agami.name, {
                      separator: ' ',
                    })}
                  </Typography>
                </td>
                <td>
                  <Typography
                    title={agami.description}
                    variant="small"
                  >
                    {truncate(agami.description, {
                      separator: ' ',
                    })}
                  </Typography>
                </td>
                <td>
                  <Typography variant="small">
                    {new Date(agami.inserted_at).toLocaleDateString('en-GB')}
                  </Typography>
                </td>
                <td>
                  <PlayerMini audioUrl={agami.audioUrl} />
                </td>
                <td>
                  <AgamiTypeChip agamiType={agami.type} />
                  {agami.type === 2
                    && agami?.experience_content_spots?.[0].manual_code
                    && (
                      <Typography
                        variant="small"
                      >
                        Playback code:
                        {' '}
                        <span className="font-bold italic">
                          {agami?.experience_content_spots?.[0].manual_code}
                        </span>
                      </Typography>
                    )}
                </td>
                <td className="p-4 text-right">
                  <IconButton
                    variant="text"
                    size="sm"
                    color="yellow"
                    onClick={(e) => remove(e, agami)}
                  >
                    <PiTrash className="text-primary text-[20px]" />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ExperienceSpotsList;

import React from 'react';
import { Typography } from '@material-tailwind/react';
import Container from '../components/Container';
import FaqParagraph from '../components/FaqParagraph';
import { usePageTitle } from '../hooks/toolkit';

const FaqPage = () => {
  usePageTitle('FAQ | Agami');
  return (
    <Container direction="col">
      <Typography
        variant="h1"
        color="white"
        className="mb-12"
      >
        FAQ
      </Typography>

      <FaqParagraph
        id="account-faq"
        title="Do I need an account to access Agami experiences?"
        text="Yes! You may choose to create your account for free, or a paid subscription with the Creator or Venue plans."
      />

      <FaqParagraph
        id="code-faq"
        title="What is an Early Bird upgrade code?"
        text={`
          It's a 10 digits code reserved to Indiegogo Early Bird backers. 
          If you supported our campaign you should have recieved the code by email.
          If not, or if you're having troubles redeeming your code, please contact us at agami@mezzoforte.design!
        `}
      />

      <FaqParagraph
        id="spot-faq"
        title="What's an 'agami'?"
        text={`
        It's an audio content associated with a geographical location. You can define a position,
        anywhere in the world, and a radius in meters.
        Once uploaded it will appear in our "Experiences": the sound will automatically start as you get inside the radius!
      `}
      />

      <FaqParagraph
        id="headphones-faq-1"
        title="Do I need Mezzo Forte open ear headphones to use agami?"
        text={`
          No! Everyone can use agami. Since most of the contents 
          are conceived for open ear listening, you will 
          have an optimal experience with open ear headphones, 
          and the best experience with Mezzo Forte ones.`}
      />

      <FaqParagraph
        id="headphones-faq-2"
        title="Can I use Mezzo Forte open ear headphones without agami?"
        text={`
          Sure. You can use them as a standard bluetooth headset with your mobile and desktop devices. 
          They have a microphone, for calls or voice recordings. 
          You can also use them as a standalone Mp3 player (8 GB internal storage). 
          Just have in mind that your audio contents will not sound the same, since they are not 
          optimized for bone conduction. For the best sound quality with bone conduction, agami is what you need.
          `}
      />

      <FaqParagraph
        id="cloud-faq"
        title="Are agami contents stored on the cloud or on the headphones?"
        text={`
        Agami stores sound contents on the cloud, with no use of the 8 GB internal storage on Mezzo Forte headphones.
        `}
      />

      <FaqParagraph
        id="position-faq"
        title="Agami can&apos;t get my position. Why?"
        text={`
          Agami experiences are based on your position, therefore your authorization 
          is needed to use your device's localization feature. 
          Turn on the localization on your device, 
          then authorize your browser to use localization.
        `}
      />

      <FaqParagraph
        id="bch-faq"
        title="What does BCH stand for?"
        text={`
          BCH stands for Bone Conduction Headphones. 
          Unlike traditional headsets, bch leave your ear canal 
          free and don't block out surrounding sounds - you keep 
          hearing with your ears, but other sounds come through 
          the headphones, straight into the head.
        `}
      />

    </Container>
  );
};

export default FaqPage;

import React from 'react';
import { Typography } from '@material-tailwind/react';
import { usePageTitle } from '../hooks/toolkit';
import Container from '../components/Container';
import PlanCard from '../components/PlanCard';

const About = () => {
  usePageTitle('About | Agami');
  return (
    <Container
      direction="col"
      className="text-white"
    >
      <Typography variant="h1">ABOUT</Typography>

      <div className="w-full mt-8 text-left">
        {/* par 1 */}
        <Typography variant="h2">
          agami - by mezzoforte
        </Typography>
        <Typography className="mt-2 mb-4">
          Mezzo Forte is a creative tech studio of sound designers,
          engineers, developers and researchers with decades
          of experience in live electronics and spatial audio.
          Since 2019, Mezzo Forte creative R&D is focused on
          high-quality immersive experiences for museums,
          touristic or natural places, performance venues, indoor or outdoor.
        </Typography>
        <Typography className="mt-2 mb-4">
          <strong className="font-black">agami</strong>
          {' '}
          is the first pro audio platform
          for open ear immersive experiences, based on
          creative R&D by Mezzo Forte team. Sounds are
          associated with a place of interest, by designing
          the ecological blend between the natural
          sound environment and virtual sound sources.
        </Typography>

        <hr className="w-full md:w-1/2 my-6 opacity-50" />

        {/* par 2 */}
        <Typography variant="h2">
          agami - an overview
        </Typography>
        <Typography className="mt-2 mb-4">
          Sounds are uploaded to agami by content
          {' '}
          <strong className="font-black">creators</strong>
          .
          They are organized in
          {' '}
          <strong className="font-black">experiences</strong>
          {' '}
          by cultural, touristic, and performance
          {' '}
          <strong className="font-black">venues</strong>
          .
        </Typography>
        <Typography className="mt-2 mb-4">
          <strong className="font-black">Agami global</strong>
          {' '}
          experience is free, both for access and upload.
          It&apos;s a global collaborative soundwork, introducing a new extended
          reality paradigm that can be accessed everywhere, by everyone.
          A new way to listen to the world around us.
        </Typography>
        <Typography className="mt-2 mb-4">
          <strong className="font-black">Pro</strong>
          {' '}
          experiences are created by venues: their quality is
          optimized by advanced audio processing algorithms,
          and is certified by Mezzo Forte.
          Venues propose them to users for free or purchase.
          Pro experiences can be accessed even without internet connection: in this case,
          venues previously download them as an app on mobile devices.
        </Typography>

        <hr className="w-full md:w-1/2 my-6 opacity-50" />

        {/* par 3 */}
        <Typography variant="h2">
          agami - plans
        </Typography>

        <div className="my-4 grid grid-cols-1 md:grid-cols-3 gap-4">
          <PlanCard
            planName="FREE"
            price={0}
            features={[
              'free forever, really',
              'free access to the global experience',
              'access to pro experiences (after purchase)',
              '1 upload to the global experience',
            ]}
          />

          <PlanCard
            planName="CREATOR"
            price={29}
            features={[
              'unlimited upload to the global experience',
              'upload soundworks to the creator showcase',
              'boosted visibility with Venues',
              'optimized quality with Mezzo Forte sound processing algorithms',
              'content validation and feedback from the Mezzo Forte team',
            ]}
          />

          <PlanCard
            planName="VENUE"
            price={250}
            features={[
              'access to global experience and creator showcases',
              'unlimited upload to the global experience',
              'private sounds library for upload and cherry picking from creator showcases',
              'create pro experiences from sounds library or by commissioning selected creators to design new contents',
              'Mezzo Forte support on narrative strategy and sound design',
            ]}
          />
        </div>
      </div>

    </Container>
  );
};

export default About;

import React, {
  useContext,
  useState,
  createContext,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { dbSelect } from '../utils/helpers/supabase.helpers';
import { useSessionContext } from './SessionContext';

const LabelsContext = createContext({
  getSpotTypeLabel: () => { },
  getAudioStatusLabel: () => { },
  loading: true,
});

export const useLabelsContext = () => useContext(LabelsContext);

const LabelsContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const { loading: sessionLoading } = useSessionContext();
  const [spotTypes, setSpotTypes] = useState(null);
  const [audioStatusTypes, setAudioStatusTypes] = useState(null);

  const fetchLabels = async () => {
    const types = await dbSelect({ table: 'spot_types' });

    if (types.error) {
      console.error(types.error);
    }

    if (!types.error && types.data) {
      setSpotTypes(types.data);
    }

    const audioStatus = await dbSelect({ table: 'spot_audio_status' });

    if (audioStatus.error) {
      console.error(audioStatus.error);
    }

    if (!audioStatus.error && audioStatus.data) {
      setAudioStatusTypes(audioStatus.data);
    }

    setLoading(false);
  };

  const getSpotTypeLabel = useCallback((spotTypeId) => {
    if (!spotTypeId || !spotTypes) return;
    const entry = spotTypes.find((spotType) => spotType.id === spotTypeId);
    return entry?.label;
  }, [spotTypes]);

  const getAudioStatusLabel = useCallback((spotAudioStatusId) => {
    if (!spotAudioStatusId || !audioStatusTypes) return;
    const entry = audioStatusTypes.find((audioStatus) => audioStatus.id === spotAudioStatusId);
    return entry?.label;
  }, [audioStatusTypes]);

  useEffect(() => {
    if (!sessionLoading) {
      fetchLabels();
    }
  }, [sessionLoading]);

  const contextValue = useMemo(
    () => ({
      getSpotTypeLabel,
      getAudioStatusLabel,
      loading,
    }),
    [
      getSpotTypeLabel,
      getAudioStatusLabel,
      loading,
    ],
  );

  return (
    <LabelsContext.Provider value={contextValue}>
      {children}
    </LabelsContext.Provider>
  );
};

export default LabelsContextProvider;

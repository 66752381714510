import { useEffect, useState } from 'react';
import { Button, Typography, IconButton } from '@material-tailwind/react';
import {
  Link, Navigate, useLocation, useSearchParams,
} from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';
import { useSessionContext } from '../contexts/SessionContext';
import { usePageTitle } from '../hooks/toolkit';
import Container from '../components/Container';
import FinalizeUserForm from '../components/FinalizeUserForm';
import FinalizeUserTitle from '../components/FinalizeUserTitle';

const UserFinalize = () => {
  usePageTitle('Finalize account | Agami');

  const location = useLocation();

  const [searchParams] = useSearchParams();

  const codeParam = searchParams.get('redeemCode');

  const { user } = useSessionContext();

  const [userType, setUserType] = useState(null);

  useEffect(() => {
    if (codeParam) {
      setUserType('indiegogo');
    }
  }, []);

  // send a logged user to its dashboard
  if (user.finalized) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return (
    <Container direction="col">

      <FinalizeUserTitle userType={userType} />

      {!userType && (
        <div className="w-full md:w-1/2 flex flex-col gap-8">
          <Button
            variant="outlined"
            color="white"
            onClick={() => setUserType('indiegogo')}
          >
            I am an Indiegogo Supporter
          </Button>

          <Button
            variant="outlined"
            color="white"
            onClick={() => setUserType('bch-owner')}
          >
            I have my own Bone Conduction Headphones
          </Button>

          <Button
            variant="outlined"
            color="white"
            onClick={() => setUserType('profile-only')}
          >
            I don&apos;t have a Bone conduction headphones, I just want a profile
          </Button>
        </div>
      )}

      {userType && (
        <>
          <IconButton
            className="self-start"
            variant="text"
            color="white"
            size="lg"
            onClick={() => setUserType(null)}
          >
            <MdArrowBack className="text-3xl" />
          </IconButton>
          <FinalizeUserForm userType={userType} />
        </>
      )}

      {!userType && (
        <>
          <hr className="w-full md:w-1/2 my-6 opacity-50" />

          <div className="flex w-full justify-center items-center gap-2 my-2">
            <Typography
              color="white"
            >
              Don&apos;t have Bone Conduction Headphones yet?
            </Typography>
            <Link
              to="https://www.indiegogo.com/projects/agami-everywhere-immersive-sound"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography
                color="white"
                className="underline font-bold"
              >
                Buy now
              </Typography>
            </Link>
          </div>
        </>
      )}
    </Container>
  );
};

export default UserFinalize;

import { useRef, useCallback } from 'react';

import { toast } from 'react-toastify';

export const useSoundMapToast = () => {
  const toastId = useRef(null);

  const success = useCallback((msg) => {
    toast.dismiss(toastId.current);
    toast.success(msg, { containerId: 'soundmap-toast-container' });
  }, [toast]);

  const dismissAll = useCallback(
    () => toast.dismiss(),
    [toast],
  );

  return { success, dismissAll };
};

export const useGeneralToast = () => {
  const success = useCallback((msg) => {
    toast.success(msg, { containerId: 'general-toast-container' });
  }, [toast]);

  const error = useCallback((msg) => {
    toast.error(msg, { containerId: 'general-toast-container' });
  }, [toast]);

  const info = useCallback((msg) => {
    toast.info(msg, { containerId: 'general-toast-container' });
  }, [toast]);

  const warning = useCallback((msg) => {
    toast.warning(msg, { containerId: 'general-toast-container' });
  }, [toast]);

  return {
    success, error, info, warning,
  };
};

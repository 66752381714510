import React, { useState, useRef } from 'react';
import { Alert, Input, Typography } from '@material-tailwind/react';
import { useGeneralToast } from '../hooks/toast';
import {
  checkNewAgamiManualCode,
  checkExistingAgamiManualCode,
} from '../utils/helpers/supabase.helpers';

const DEBOUNCE_TIMEOUT = 500;

const SpotManualCodeSelector = ({
  formData,
  uploading,
  handleDataChange,
  spotId,
}) => {
  const toast = useGeneralToast();
  const debounceTimeoutRef = useRef();
  const [debounce, setDebounce] = useState(formData.manual_code);
  const [code, setCode] = useState(formData.manual_code);
  const [codeUnavailable, setCodeUnavailable] = useState(false);
  const [syntaxError, setSyntaxError] = useState(false);

  const checkCodeAvailable = async () => {
    if (!code) {
      handleDataChange('manual_code', '');
      setCodeUnavailable(false);
      setSyntaxError(false);
      return;
    }

    if (code.includes('§')) {
      setSyntaxError(true);
      return;
    }

    setSyntaxError(false);

    const { error, available } = spotId
      ? await checkExistingAgamiManualCode({
        spotId,
        code,
        experiences: formData.experiences,
      }) : await checkNewAgamiManualCode({
        code,
        experiences: formData.experiences,
      });

    if (error) {
      console.error(error);
      toast.error(`Error checking manual code [${error}]`);
      return;
    }

    if (!available) {
      setCodeUnavailable(true);
      handleDataChange('manual_code', '');
      return;
    }

    setCodeUnavailable(false);
    handleDataChange('manual_code', code);
  };

  React.useEffect(() => {
    clearTimeout(debounceTimeoutRef.current);
    debounceTimeoutRef.current = setTimeout(
      () => setCode(debounce),
      DEBOUNCE_TIMEOUT,
    );
    return () => clearTimeout(debounceTimeoutRef.current);
  }, [debounce]);

  React.useEffect(() => {
    checkCodeAvailable();
    return () => {
      // TODO - abort API call
    };
  }, [code, spotId, formData.experiences]);

  return (
    <>
      <Typography className="font-bold text-primary">
        Manual Playback Code
      </Typography>

      <Input
        className="w-full"
        value={debounce ?? ''}
        onChange={(e) => setDebounce(e.currentTarget.value)}
        type="text"
        label="Code"
        required
        disabled={uploading}
        error={codeUnavailable}
      />

      {codeUnavailable && (
        <Alert
          color="red"
          variant="ghost"
        >
          Code &quot;
          {code}
          &quot; is already used by another agami in the experiences you selected.
        </Alert>
      )}

      {syntaxError && (
        <Alert
          color="red"
          variant="ghost"
        >
          Code cannot contain § character
        </Alert>
      )}
    </>
  );
};

export default SpotManualCodeSelector;

import { useState, useCallback, useEffect } from 'react';
import { getBucketInfo } from '../utils/helpers/supabase.helpers';
import { useSessionContext } from '../contexts/SessionContext';
import { GEOXP_AUDIO_BUCKET } from '../utils/constants/db.constants';

export const useBucketInfo = () => {
  const { loading: loadingSession, user } = useSessionContext();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uploadSizeLimit, setUploadSizeLimit] = useState(0);
  const [allowedFileTypes, setAllowedFileTypes] = useState([]);

  const parseBucketInfo = useCallback(async () => {
    const { data, error: bucketError } = await getBucketInfo(GEOXP_AUDIO_BUCKET);

    if (bucketError) {
      setError(bucketError);
      setLoading(false);
      return;
    }

    const { file_size_limit, allowed_mime_types } = data;
    setUploadSizeLimit(file_size_limit);
    setAllowedFileTypes(allowed_mime_types);
    setError(null);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (
      loadingSession
      || !user
      || !user?.id
    ) {
      return;
    }
    parseBucketInfo();
  }, [loadingSession, user]);

  return {
    loading, error, uploadSizeLimit, allowedFileTypes,
  };
};

import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';

export const metersToPixelsAtMaxZoom = (meters, latitude) => (
  meters / 0.075 / Math.cos((latitude * Math.PI) / 180)
);

const getMinOrMax = (positions, minOrMax, latOrLng) => {
  if (minOrMax === 'max') {
    return (maxBy(positions, (value) => value[latOrLng]))[latOrLng];
  }
  return (minBy(positions, (value) => value[latOrLng]))[latOrLng];
};

export const getBoundsFromPositions = (positions) => {
  const maxLat = getMinOrMax(positions, 'max', 'lat');
  const minLat = getMinOrMax(positions, 'min', 'lat');
  const maxLng = getMinOrMax(positions, 'max', 'lon');
  const minLng = getMinOrMax(positions, 'min', 'lon');
  const southWest = [minLng, minLat];
  const northEast = [maxLng, maxLat];
  return [southWest, northEast];
};

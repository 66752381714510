import React, { useState } from 'react';
import { Spinner } from '@material-tailwind/react';
import { useSessionContext } from '../contexts/SessionContext';
import { formatDateString } from '../utils/helpers/dates.helpers';
import { useUploadSpotLimit } from '../hooks/authorization';
import { getUserGeoXpSpotCount } from '../utils/helpers/user-spot.helpers';
import { useMountEffect } from '../hooks/toolkit';
import RoleChip from './RoleChip';

const UserDataPanel = () => {
  const { user } = useSessionContext();
  const [uploadedSpotsCount, setUploadedSootsCount] = useState(null);
  const spotsLimit = useUploadSpotLimit();
  const spotsLimitLabel = spotsLimit === Infinity ? 'unlimited' : spotsLimit;

  useMountEffect(() => {
    getUserGeoXpSpotCount()
      .then(({ count }) => setUploadedSootsCount(count))
      .catch(console.error);
  });

  if (!user) {
    return <>user not defined</>;
  }

  return (
    <div className="w-full mt-6">
      <div className="w-full flex flex-col justify-start items-start gap-2">
        <span>
          <strong>
            {user.completeName}
          </strong>
        </span>

        <span className="inline-flex gap-2">
          Member since:
          {' '}
          <strong>{formatDateString(user.confirmedAt)}</strong>
        </span>

        <span className="inline-flex gap-2">
          Current role:
          {' '}
          <RoleChip role={user.role} />
        </span>

        {user.subscription && (
          <>
            <span className="inline-flex gap-2">
              Current subscription started:
              {' '}
              <strong>{formatDateString(user.subscription.start_date)}</strong>
            </span>
            <span className="inline-flex gap-2">
              Current subscription expires:
              {' '}
              {user.subscription.end_date
                ? <strong>{formatDateString(user.subscription.end_date)}</strong>
                : <strong>never</strong>}
            </span>
          </>
        )}

        <span className="inline-flex gap-2">
          Spots uploaded:
          {' '}
          <strong>
            {uploadedSpotsCount ?? <Spinner className="inline-block h-3 w-3" />}
            {' '}
            /
            {' '}
            {spotsLimitLabel}
          </strong>
        </span>
      </div>
    </div>
  );
};

export default UserDataPanel;

import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Chip,
  Spinner,
  Typography,
  Button,
  Tabs,
  IconButton,
  ButtonGroup,
} from '@material-tailwind/react';
import {
  PiPathFill, PiGear, PiGridFour, PiList,
} from 'react-icons/pi';
import { AiOutlinePlus } from 'react-icons/ai';
import ExperienceSpotsCards from '../ViewDashboard/ExperienceSpotsCards';
import ExperienceSpotsList from '../ViewDashboard/ExperienceSpotsList';
import GoBack from '../components/GoBack';
import { useUserAgamiListByExperience, useUserExperienceDetails } from '../hooks/agami';
import { usePageTitle, useScreenBreakpoint } from '../hooks/toolkit';
import RemoveAgamiFromExperienceDialog from '../ViewDashboard/RemoveAgamiFromExperienceDialog';
import AddAgamiToExperience from '../ViewDashboard/AddAgamiToExperience';
import ExperienceSettings from '../ViewDashboard/ExperienceSettings';

const UserExperienceDetail = () => {
  usePageTitle('Edit Experience Details | Agami Dashboard');
  const { experienceId } = useParams();
  const [showDetails, setShowDetails] = useState(false);
  const [vizMode, setVizMode] = useState('grid');
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [agamiToRemove, setAgamiToRemove] = useState(null);
  const screenBreakpoint = useScreenBreakpoint();
  const navigate = useNavigate();

  const {
    agamiList,
    isLoading: agamiListLoading,
    refetch: refetchAgamiList,
  } = useUserAgamiListByExperience({ experienceId });

  const {
    experienceDetails,
    isLoading: experienceDetailsLoading,
    refetch: refetchExperienceDetails,
  } = useUserExperienceDetails({ experienceId });

  const startRemove = (agami) => {
    setAgamiToRemove(agami);
    setRemoveDialogOpen(true);
  };

  return (
    <div className="py-4">

      <RemoveAgamiFromExperienceDialog
        isOpen={removeDialogOpen}
        setIsOpen={setRemoveDialogOpen}
        experienceId={experienceId}
        agamiToRemove={agamiToRemove}
        experienceDetails={experienceDetails}
        refetchAgamiList={refetchAgamiList}
      />

      {experienceDetailsLoading && (
        <Spinner className="h-6 w-6" />
      )}

      {!experienceDetailsLoading && !experienceDetails && (
        <Typography>Looks like there&apos;s nothing here :(</Typography>
      )}

      {!experienceDetailsLoading && experienceDetails && (
        <>
          <AddAgamiToExperience
            isOpen={addDialogOpen}
            setIsOpen={setAddDialogOpen}
            currentExpId={experienceId}
            refetchAgamiList={refetchAgamiList}
          />

          <div className="flex w-full">
            {/* LEFT PART */}
            <div className="grow transition-all duration-[400ms]">
              <Tabs value="grid">

                {/* FIRST ROW */}
                <div className="flex items-start md:items-center justify-between gap-2 mb-2">

                  <div className="flex flex-col md:flex-row md:items-center justify-center gap-2">

                    <GoBack />

                    <Typography variant="lead">
                      {experienceDetails.name}
                      {agamiList?.length > 0 && (
                        <>
                          {' '}
                          <span
                            className="text-base text-gray-600"
                          >
                            (
                            {agamiList?.length}
                            {' '}
                            agami)
                          </span>
                        </>
                      )}
                    </Typography>

                    {experienceDetails.is_active && (
                      <Chip
                        value="active"
                        className="inline-block self-start md:self-center"
                        color="green"
                        variant="ghost"
                      />
                    )}
                  </div>

                  <div className="flex items-center justify-center gap-2">

                    <ButtonGroup
                      size="sm"
                      variant="outlined"
                      color="gray"
                      className="hidden md:block"
                    >
                      <Button
                        color="primary"
                        className={`transition-all duration-[400ms] ${vizMode === 'grid' ? 'border-primary text-primary bg-white-yellow' : ''}`}
                        onClick={() => setVizMode('grid')}
                      >
                        <PiGridFour className="h-5 w-5" />
                      </Button>
                      <Button
                        className={`transition-all duration-[400ms] ${vizMode === 'list' ? 'border-primary text-primary bg-white-yellow' : ''}`}
                        onClick={() => setVizMode('list')}
                      >
                        <PiList className="h-5 w-5" />
                      </Button>
                    </ButtonGroup>

                    <IconButton
                      // size="sm"
                      size={screenBreakpoint === 'sm' ? 'md' : 'sm'}
                      variant="text"
                      color="gray"
                      onClick={() => setShowDetails((prev) => !prev)}
                    >
                      <PiGear className="w-6 md:w-5 h-6 md:h-5 text-primary" />
                    </IconButton>

                  </div>
                </div>

                {/* CONTROLS ROW */}
                <div className="flex items-center justify-between gap-2 mb-2">
                  <div className="flex gap-4">

                    <Button
                      color="primary"
                      className="flex justify-center items-center gap-2"
                      size="sm"
                      onClick={() => setAddDialogOpen(true)}
                    >
                      <AiOutlinePlus />
                      ADD AN AGAMI
                    </Button>

                    <Button
                      size="sm"
                      className="flex justify-center items-center gap-2"
                      onClick={() => navigate(`/experience/${experienceId}`)}
                    >
                      <PiPathFill />
                      SEE ON MAP
                    </Button>
                  </div>
                </div>

                {/* CONTENT ROW */}
                <div className="flex gap-4">

                  {/* SPOTS */}
                  <div className="grow transition-all duration-[400ms]">

                    {vizMode === 'grid' && (
                      <ExperienceSpotsCards
                        agamiListLoading={agamiListLoading}
                        agamiList={agamiList}
                        removeFromExperience={startRemove}
                      />
                    )}

                    {vizMode === 'list' && (
                      <ExperienceSpotsList
                        agamiListLoading={agamiListLoading}
                        agamiList={agamiList}
                        removeFromExperience={startRemove}
                      />
                    )}
                  </div>
                </div>
              </Tabs>
            </div>

            {/* SETTINGS */}
            <ExperienceSettings
              experienceDetails={experienceDetails}
              refetchExperienceDetails={refetchExperienceDetails}
              closeSidebar={() => setShowDetails(false)}
              sidebarIsClosed={!showDetails}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default UserExperienceDetail;

import React from 'react';

const SpotIcon = () => (
  <span className="inline-block">
    <svg
      width="14"
      height="24"
      viewBox="0 0 14 24"
      fill="none"
    >
      <path
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.49839 9.69013C3.21255 9.35533 2 8.13643 2 6.50596C2 3.4326 4.34489 2 6.996 2C9.64712 2 11.992 4.29533 11.992 7.12649C11.992 7.12649 12.4209 11.4552 6.996 18L4.09112 14.3401"
      />
      <ellipse
        cx="7"
        cy="22"
        rx="5"
        ry="2"
        fill="currentColor"
      />
    </svg>
  </span>
);

export default SpotIcon;

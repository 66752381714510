export const mimeTypeExtension = (mime) => {
  // eslint-disable-next-line default-case
  switch (mime) {
    case 'audio/mpeg':
      return 'mp3';
    case 'audio/mp4':
      return 'mp4';
    case 'audio/ogg':
      return 'ogg';
    case 'audio/vorbis':
      return 'oga';
  }
  return 'no extension found';
};

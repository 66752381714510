import Bowser from 'bowser';

export const useDevice = () => {
  if (!window) {
    return;
  }

  const browser = Bowser.getParser(window.navigator.userAgent);

  const platformType = browser.getPlatformType();

  const osName = browser.getOSName();

  const browserName = browser.getBrowserName();

  return {
    platformType,
    osName,
    browserName,
  };
};

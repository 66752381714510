import React, { useMemo } from 'react';

import * as L from 'leaflet';

import {
  MapContainer, Marker, TileLayer,
} from 'react-leaflet';

import { DEFAULT_ICON } from '../utils/constants/map.constants';

import { MAPBOX_MONOCHROME_TILESET_URL } from '../utils/constants/mapbox.constants';

const SpotPositionBanner = ({
  latitude,
  longitude,
}) => {
  const center = useMemo(
    () => (latitude && longitude ? L.latLng(latitude, longitude) : null),
    [latitude, longitude],
  );

  return center && (
    <div className="h-full w-full">
      <MapContainer
        center={center}
        zoom={15}
        scrollWheelZoom={false}
        zoomControl={false}
        className="h-full w-full z-0"
        attributionControl={false}
      >
        <TileLayer url={MAPBOX_MONOCHROME_TILESET_URL} />
        <Marker
          position={center}
          icon={DEFAULT_ICON}
        />
      </MapContainer>
    </div>
  );
};

export default SpotPositionBanner;

import { useMemo } from 'react';
import flatten from 'lodash/flatten';
import { useXp } from '../contexts/XpContext';
import XpSpotLayer from './XpSpotLayer';

const XpSpotsMarkers = () => {
  const {
    positions,
    active,
    config,
  } = useXp();

  const activePosIds = useMemo(
    () => active?.map((audio) => audio?.spot?.position),
    [active],
  );

  const enrichedPositions = useMemo(() => {
    if (!positions || !config) return;
    const everySpot = flatten(
      config.experience.patterns.map((pattern) => pattern.spots),
    );
    return positions.map((pos) => {
      const spotData = everySpot.find((spot) => spot.position === pos.id);
      return ({
        ...pos,
        label: spotData?.label ?? '',
        description: spotData?.description ?? '',
      });
    });
  }, [positions, config]);

  return (
    // eslint-disable-next-line
    <>
      {enrichedPositions?.map((position) => (
        <XpSpotLayer
          key={`${position.id}_layer`}
          activePosIds={activePosIds}
          position={position}
        />
      ))}
    </>
  );
};

export default XpSpotsMarkers;

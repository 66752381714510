import { useMemo } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { PiUserCircleFill, PiKeyLight, PiPathFill } from 'react-icons/pi';
import { AiOutlineArrowRight, AiFillPlusCircle } from 'react-icons/ai';
import { BiSolidDashboard } from 'react-icons/bi';
import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from '@material-tailwind/react';
import { useSessionContext } from '../contexts/SessionContext';
import { useFeatureAuth } from '../hooks/authorization';
import { FEATURES } from '../utils/constants/features.constants';
import { signOut } from '../utils/helpers/supabase.helpers';
import Avatar from '../components/Avatar';
import RoleChip from '../components/RoleChip';

const Header = () => {
  const { user, refresh } = useSessionContext();
  const { pathname } = useLocation();

  const soundMapAuth = useFeatureAuth(FEATURES.SOUND_MAP);
  const uploadSpotsAuth = useFeatureAuth(FEATURES.UPLOAD_SPOTS_UI);
  const uploadExperienceAuth = useFeatureAuth(FEATURES.EXP_EDITOR_UI);
  const adminAuth = useFeatureAuth(FEATURES.ADMIN_PANEL);

  const links = useMemo(() => {
    if (!user?.finalized) return [];

    const userLinks = [
      {
        label: 'Dashboard',
        to: '/',
        icon: <BiSolidDashboard className="text-[18px]" />,
        testId: 'dashboard-nav-link',
        activeLinkCondition: pathname.includes('/experiences'),
      },
    ];

    if (soundMapAuth) {
      userLinks.push({
        label: 'Experience',
        to: '/experience',
        icon: <PiPathFill className="text-[18px]" />,
        testId: 'experience-nav-link',
      });
    }

    if (uploadSpotsAuth || uploadExperienceAuth) {
      userLinks.push({
        label: 'Upload',
        to: '/upload',
        icon: <AiFillPlusCircle className="text-[18px]" />,
        testId: 'upload-nav-link',
      });
    }

    return userLinks;
  }, [user, soundMapAuth, uploadSpotsAuth, uploadExperienceAuth, pathname]);

  const actions = useMemo(() => {
    if (user) return [];

    if (process.env.REACT_APP_COMING_SOON_PREVIEW) {
      return [
        {
          label: 'Available now for beta testing!',
          to: 'mailto:agami@mezzoforte.design',
          testId: 'signin-btn',
        },
        {
          label: 'CONTACT US',
          to: 'mailto:agami@mezzoforte.design',
          variant: 'text',
          testId: 'create-account-btn',
        },
      ];
    }

    return [
      {
        label: 'Sign in',
        to: '/signin',
        variant: 'outlined',
        testId: 'signin-btn',
      },
      {
        label: 'Create account',
        to: '/register',
        testId: 'create-account-btn',
      },
    ];
  }, [user]);

  const logout = async () => {
    const { error } = await signOut();
    if (error) {
      console.error(error);
      return;
    }
    refresh();
  };

  return (
    <nav
      className={`
        w-full
        flex justify-between items-center
        px-4 md:px-16 py-4 z-50
        top-0
        ${!user && pathname === '/' ? 'bg-transparent absolute' : 'bg-primary fixed'} text-white-yellow`}
    >
      <div className="w-full md:w-1/2">
        <span className="inline-block">
          <Link to="/">
            <img
              className="h-10 opacity-90 hover:opacity-100"
              alt="agami logo"
              src="https://mezzoforte.design/img/agami/agami_logo_horiz_accent.png"
            />
          </Link>
        </span>
      </div>

      <div className="space-x-12 items-center flex">
        {links && links.length > 0 && (
          <div className="space-x-12 hidden md:flex">
            {links.map((link) => {
              const linkIsActive = (
                link.activeLinkCondition
                || `/${pathname.split('/')[1]}` === link.to
              );
              return (
                <Link
                  key={link.to}
                  to={link.to}
                  className={`opacity-90 hover:opacity-100 ${linkIsActive && 'text-accent'}`}
                  data-testid={link.testId}
                >
                  {link.label}
                </Link>
              );
            })}
          </div>
        )}

        {actions && actions.length > 0 && (
          <div className="flex space-x-2 w-full">
            {actions.map((action) => (
              <Link key={action.to} to={action.to}>
                <Button
                  variant={action.variant}
                  size="sm"
                  data-testid={action.testId}
                >
                  {action.label}
                </Button>
              </Link>
            ))}
          </div>
        )}

        {!!user && user.finalized && (
          <Menu>
            <MenuHandler>
              <Button variant="text" className="p-0">
                <Avatar name={user.name} surname={user.surname} size="sm" interactive />
              </Button>
            </MenuHandler>
            <MenuList className="bg-white-yellow text-primary">
              <div className="w-full pt-[9px] pb-3 px-3 border-b">
                <Typography className="font-bold">
                  {user?.completeName}
                </Typography>
                <span className="inline-block">
                  <RoleChip role={user?.role} />
                </span>
              </div>
              {links.map((link) => (
                <Link key={link.to} to={link.to}>
                  <MenuItem className="flex md:hidden space-x-2 items-center">
                    {link.icon}
                    <Typography variant="paragraph">{link.label}</Typography>
                  </MenuItem>
                </Link>
              ))}
              <Link to="/profile">
                <MenuItem className="flex space-x-2 items-center">
                  <PiUserCircleFill className="text-[18px]" />
                  <Typography variant="paragraph">Profile</Typography>
                </MenuItem>
              </Link>
              {adminAuth && (
                <Link to="/admin">
                  <MenuItem className="flex space-x-2 items-center">
                    <PiKeyLight className="text-[18px]" />
                    <Typography variant="paragraph">Admin panel</Typography>
                  </MenuItem>
                </Link>
              )}
              <MenuItem className="flex space-x-2 items-center" onClick={logout}>
                <AiOutlineArrowRight className="text-[18px]" />
                <Typography variant="paragraph">Sign out</Typography>
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </div>

    </nav>
  );
};

export default Header;

import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import * as GeoXp from '@mezzo-forte/geoxp';
import { useOnInit } from '../hooks/toolkit';
import { useLogs } from './LogsContext';
import { EMPTY_GEOXP_CONFIG } from '../utils/constants/geoxp.constants';
import { removeManualCodeLibrarySuffix } from '../utils/helpers/agami.helpers';

const XpContext = React.createContext({
  config: undefined,
  hasManualSpots: false,
  positions: [],
  active: undefined,
  location: undefined,
  manualMode: false,
  volume: 1,
  loadConfig: () => { },
  setManualMode: () => { },
  setVolume: () => { },
  updateLocation: () => { },
  unlock: () => { },
  destroy: () => { },
  start: () => { },
  playManually: () => { },
});

const useXp = () => useContext(XpContext);

const XpContextProvider = ({ children }) => {
  const init = useOnInit();
  const logs = useLogs();

  const [config, setConfig] = useState(null);
  const [positions, setPositions] = useState([]);
  const [active, setActive] = useState([]);
  const [location, setLocation] = useState();
  const [manualMode, setManualMode] = useState(false);
  const [volume, setVolume] = useState(1);
  const [mute, setMute] = useState(false);

  // singleton geoXp instance
  const geoXpInstance = useMemo(
    () => new GeoXp(EMPTY_GEOXP_CONFIG),
    [],
  );

  // configuration
  const updatePositions = useCallback((geoConfig) => {
    if (!geoConfig) return;

    // assign default radius and deadband if missing
    const completePositions = geoConfig.positions.map((pos) => ({
      ...pos,
      radius: pos.radius || geoConfig.options.defaultRadius,
      deadband: pos.deadband || geoConfig.options.defaultDeadband,
    }));
    setPositions(completePositions);
  }, []);

  const loadConfig = useCallback((configData) => {
    if (!configData) return;

    setConfig(configData);
    updatePositions(configData.geo);
  }, [updatePositions]);

  const initConfig = useCallback(() => {
    if (!geoXpInstance) return;
    if (!config) return;

    // reload geoxp config
    geoXpInstance.reload(config);

    // enable/disable internalGeolocation
    geoXpInstance.internalGeolocation(!manualMode);
  }, [geoXpInstance, config, manualMode]);

  const hasManualSpots = useMemo(
    () => {
      if (!config) return false;

      let manualSpot = false;
      const patterns = config?.experience?.patterns || [];
      patterns.forEach((p) => {
        if (p.spots.find((s) => s.code)) {
          manualSpot = true;
        }
      });
      return manualSpot;
    },
    [config],
  );

  // runtime
  const updateLocation = useCallback(({ latitude, longitude }) => {
    if (!geoXpInstance) return;
    if (!manualMode) return;
    if (!latitude || !longitude) return;

    geoXpInstance.updateGeolocation({
      coords: {
        latitude,
        longitude,
        accuracy: 1,
      },
    });
  }, [geoXpInstance, manualMode]);

  const destroy = useCallback(() => {
    if (!geoXpInstance) return;

    geoXpInstance.clearCookies();
    geoXpInstance.destroy();

    logs.msg('GeoXp instance destroied');
  }, [geoXpInstance, logs]);

  const unlock = useCallback(() => {
    if (!geoXpInstance) return;

    geoXpInstance.unlock();
  }, [geoXpInstance]);

  const start = useCallback(() => {
    if (!geoXpInstance) return;
    if (!config) return;

    initConfig();
    unlock();
  }, [config, geoXpInstance, initConfig, unlock]);

  const playManually = useCallback((code) => {
    if (!geoXpInstance) return;

    const patterns = config?.experience?.patterns || [];

    /**
     * check with regex for spots that have ${code}§lib_<uuid>
     * (if library experience the will have it)
     * then, if multiple correspondance are found, notify user
     */
    const toPlayIds = patterns.reduce(
      (acc, curr) => {
        const found = curr.spots
          ?.filter((s) => removeManualCodeLibrarySuffix(s.code) === code)
          ?.map((s) => s.id);
        return [...acc, ...found];
      },
      [],
    );

    if (!toPlayIds || toPlayIds.length === 0) {
      return 'spot not found';
    }

    if (toPlayIds.length > 1) {
      return 'multiple spots found with this code';
      // TODO - show dialog and let user choose what to play!
    }

    return geoXpInstance.forceSpot(toPlayIds[0]);
  }, [config]);

  // add event handlers on init
  useEffect(() => {
    if (!init || !geoXpInstance) return;

    window.geoXp = geoXpInstance;

    // geoXp events subscription
    geoXpInstance.on('active', (spotData) => {
      logs.msg('[GEOXP EVENT] - Spot active', spotData.id, spotData.label);
    });

    geoXpInstance.on('outgoing', (spotData) => {
      logs.msg('[GEOXP EVENT] - Spot outgoing', spotData.id, spotData.label);
    });

    geoXpInstance.on('visited', (spotData) => {
      logs.msg('[GEOXP EVENT] - Spot visited', spotData.id, spotData.label);
    });

    geoXpInstance.on('play', (audioData) => {
      logs.msg(
        '[GEOXP EVENT] - Play audio',
        audioData.id,
        audioData.spot.id,
        audioData.spot.label,
      );
      setActive((prevActive) => {
        const prevIds = prevActive?.map((audio) => audio.id);
        return !prevIds.includes(audioData.id)
          ? [...prevActive, audioData]
          : prevActive;
      });
    });

    geoXpInstance.on('stop', (audioData) => {
      logs.msg(
        '[GEOXP EVENT] - Stop audio',
        audioData.id,
        audioData.spot.id,
        audioData.spot.label,
      );
      setActive((prevActive) => prevActive?.filter((audio) => audio?.id !== audioData?.id));
    });

    geoXpInstance.on('position', (positionData) => {
      // logs.msg('[GEOXP EVENT] - New position', positionData);
      setLocation(positionData);
    });
  }, [geoXpInstance, init, logs]);

  useEffect(() => {
    if (!geoXpInstance) return;
    geoXpInstance.internalGeolocation(!manualMode);
  }, [manualMode, geoXpInstance]);

  useEffect(() => {
    if (!geoXpInstance) return;
    geoXpInstance.audio.setVolume(volume);
  }, [volume, geoXpInstance]);

  useEffect(() => {
    if (!geoXpInstance) return;

    if (mute) geoXpInstance.audio.setVolume(0);
    else geoXpInstance.audio.setVolume(volume);
  }, [mute, volume, geoXpInstance]);

  const contextValue = useMemo(
    () => ({
      config,
      hasManualSpots,
      positions,
      active,
      location,
      manualMode,
      volume,
      mute,
      loadConfig,
      setManualMode,
      setVolume,
      setMute,
      updateLocation,
      unlock,
      destroy,
      start,
      playManually,
    }),
    [
      config,
      hasManualSpots,
      positions,
      active,
      location,
      manualMode,
      volume,
      mute,
      loadConfig,
      setManualMode,
      setVolume,
      setMute,
      updateLocation,
      unlock,
      destroy,
      start,
      playManually,
    ],
  );

  return (
    <XpContext.Provider value={contextValue}>
      {children}
    </XpContext.Provider>
  );
};

export { XpContextProvider, useXp };

import PropTypes from 'prop-types';

const NumberOrEmptyString = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.oneOf(['']),
]);

export const GeoXpPositionPropType = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.string,
  lon: PropTypes.number,
  lat: PropTypes.number,
  radius: PropTypes.number,
  deadband: PropTypes.number,
});

const OwenPropType = PropTypes.shape({
  name: PropTypes.string,
  surname: PropTypes.string,
});

export const ExperienceCardPropType = PropTypes.shape({
  experienceId: PropTypes.string,
  isActive: PropTypes.bool,
  isLibrary: PropTypes.bool,
  isSelected: PropTypes.bool,
  name: PropTypes.string,
  owner: OwenPropType,
});

export const SharedPropType = PropTypes.shape({
  role: PropTypes.number,
  userEmail: PropTypes.string,
  useId: PropTypes.string,
});

export const ExperienceDataPropType = PropTypes.shape({
  accuracy: PropTypes.number,
  created_at: PropTypes.string,
  default_deadband: PropTypes.number,
  default_radius: PropTypes.number,
  enable_high_accuracy: PropTypes.bool,
  fade_in_time: PropTypes.number,
  fade_out_time: PropTypes.number,
  id: PropTypes.string,
  is_active: PropTypes.bool,
  is_canceled: PropTypes.bool,
  is_default: PropTypes.bool,
  name: PropTypes.string,
  owner_id: PropTypes.string,
  spots_overlap: PropTypes.bool,
  spots_replay: PropTypes.bool,
  shared_with: PropTypes.arrayOf(SharedPropType),
});

export const AgamiSpotDetailPropType = PropTypes.shape({
  audio_content_path: PropTypes.string,
  audioUrl: PropTypes.string, // optional
  audio_status: PropTypes.number,
  bch_optimized: PropTypes.number,
  description: PropTypes.string,
  experience_content_spots: PropTypes.arrayOf(PropTypes.shape({
    experience_id: PropTypes.string,
  })), // optional
  id: PropTypes.string,
  inserted_at: PropTypes.string,
  is_canceled: PropTypes.bool,
  latitude: NumberOrEmptyString,
  longitude: NumberOrEmptyString,
  name: PropTypes.string,
  radius: NumberOrEmptyString,
  type: PropTypes.number,
  user_id: PropTypes.string,
});

export const SpotTypes = PropTypes.shape({
  [PropTypes.number]: PropTypes.string,
});

export const AgamiSpotPropType = PropTypes.shape({
  experiences: PropTypes.arrayOf(PropTypes.string),
  spotName: PropTypes.string,
  description: PropTypes.string,
  latitude: NumberOrEmptyString,
  longitude: NumberOrEmptyString,
  radius: NumberOrEmptyString,
  type: PropTypes.number,
  manual_code: PropTypes.string,
});

export const LinkPropType = PropTypes.shape({
  activeLinkCondition: PropTypes.bool,
  label: PropTypes.string,
  to: PropTypes.string,
  testId: PropTypes.string,
  Icon: PropTypes.elementType,
});

export const AccessCodeType = PropTypes.shape({
  access_expires_after: PropTypes.number,
  code: PropTypes.string,
  created_at: PropTypes.string,
  created_by: PropTypes.string,
  experience_id: PropTypes.string,
  expires_at: PropTypes.string,
  id: PropTypes.string,
  is_deleted: PropTypes.bool,
  updated_at: PropTypes.string,
  anonymous_access: PropTypes.bool,
});

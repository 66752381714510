import React, { useState } from 'react';
import { Spinner, Typography, IconButton } from '@material-tailwind/react';
import { AiOutlinePlus } from 'react-icons/ai';
import { usePageTitle } from '../hooks/toolkit';
import { useExperiencesAccessCodes, useUserExperiences } from '../hooks/agami';
import NoUploads from '../components/NoUploads';
import AccessCodePreviewCard from '../ViewDashboard/AccessCodePreviewCard';
import ResponsiveDialog from '../components/ResponsiveDialog';
import CreateAccessCodeForm from '../components/CreateAccessCodeForm';
import Center from '../components/Center';

const DashboardAccessCodes = () => {
  usePageTitle('Manage Access Codes | Agami Dashboard');
  const { experienceList, isLoading: expLoading } = useUserExperiences({
    excludeDefaultExperience: true,
  });
  const { accessCodesList, isLoading: codesLoading, refetch } = useExperiencesAccessCodes();
  const isLoading = expLoading || codesLoading;
  const [openCreate, setOpenCreate] = useState(false);
  return (
    <>
      <ResponsiveDialog
        isOpen={openCreate}
        setIsOpen={setOpenCreate}
        title="Create new access code"
      >
        {isLoading
          ? (
            <Center>
              <Spinner />
            </Center>
          ) : (
            <CreateAccessCodeForm
              experienceList={experienceList}
              refetch={refetch}
              closeDialog={() => setOpenCreate(false)}
            />
          )}
      </ResponsiveDialog>

      <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center py-4">
        <Typography
          variant="h1"
          className="text-2xl md:text-5xl"
        >
          EXPERIENCES ACCESS CODES
        </Typography>
        <div className="flex items-center space-x-4">
          <IconButton
            size="sm"
            color="primary"
            className="rounded-full"
            onClick={() => setOpenCreate(true)}
          >
            <AiOutlinePlus className="text-[20px]" />
          </IconButton>
          <Typography className="font-bold">Create access code</Typography>
        </div>
      </div>

      <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
        {isLoading && (
          <Spinner className="h-6 w-6" />
        )}

        {!isLoading && (!accessCodesList || accessCodesList.length === 0) && (
          <div className="w-full col-span-3 text-center">
            <NoUploads>
              <Typography>
                Looks like you haven&apos;t created any
                {' '}
                <strong>ACCESS CODES</strong>
                {' '}
                yet.
              </Typography>
            </NoUploads>
          </div>
        )}

        {!isLoading && accessCodesList?.map((accCode) => (
          <div key={accCode.id} className="flex-1 mb-4 basis-1/4">
            <AccessCodePreviewCard
              accessCode={accCode}
              experienceList={experienceList}
              refetchCodesList={refetch}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default DashboardAccessCodes;

import React from 'react';
import { Typography } from '@material-tailwind/react';
import { useDevice } from '../hooks/device';
import { useMapControls } from '../contexts/MapControlsContext';

const ManualModeInstructions = () => {
  const { platformType } = useDevice();
  const { dragging } = useMapControls();
  const isMobile = platformType === 'mobile';

  const desktopMesssage = dragging
    ? 'Release the marker to simulate your position'
    : 'Click on the map or drag marker the to simulate your position';

  const message = isMobile ? 'Tap on the map' : desktopMesssage;

  return (
    <div className="absolute p-3 left-[50%] z-[999] top-2">
      <div className="relative -left-[50%] rounded-lg shadow py-2 px-4 bg-accent-500/70">
        <Typography
          variant={isMobile ? 'small' : 'paragraph'}
          className="font-bold text-primary"
        >
          {message}
        </Typography>
      </div>
    </div>
  );
};

export default ManualModeInstructions;

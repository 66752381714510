import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Tooltip, Chip } from '@material-tailwind/react';
import { PiInfoLight, PiCheckThin } from 'react-icons/pi';
import { VscClose } from 'react-icons/vsc';
import { AgamiSpotPropType, ExperienceDataPropType } from '../PropTypes';

const AgamiExperienceSelector = ({
  userExperiences,
  formData,
  setFormData,
}) => {
  // toggle experience
  const handleExpChipClick = (exp) => {
    setFormData((prevData) => (
      prevData.experiences.includes(exp.id)
        ? {
          ...prevData,
          experiences: prevData.experiences.filter(
            (prevExp) => prevExp !== exp.id,
          ),
        }
        : {
          ...prevData,
          experiences: [...prevData.experiences, exp.id],
        }
    ));
  };

  return (
    <>
      <div className="flex gap-1 justify-start items-center">
        <Typography
          className="font-bold text-primary"
        >
          Add to experience
        </Typography>
        <Tooltip
          content={(
            <div className="w-[300px]">
              <Typography
                variant="small"
                className="font-bold"
              >
                Select the experiences to which you want to add this agami.
                This operation can also be done at at a later time.
              </Typography>
            </div>
          )}
          placement="right"
          interactive
        >
          <span>
            <PiInfoLight className="text-primary" />
          </span>
        </Tooltip>
      </div>

      {userExperiences?.map((exp) => (
        <span
          key={exp.id}
          className="inline-block cursor-pointer"
        >
          <Chip
            className="inline-block"
            value={exp.name}
            color={formData.experiences.includes(exp.id) ? 'primary' : 'gray'}
            variant={formData.experiences.includes(exp.id) ? 'filled' : 'outlined'}
            onClick={() => handleExpChipClick(exp)}
            icon={
              formData.experiences.includes(exp.id)
                ? <PiCheckThin className="h-5 w-5" />
                : <VscClose className="h-5 w-5" />
            }
          />
        </span>
      ))}
    </>
  );
};

AgamiExperienceSelector.propTypes = {
  userExperiences: PropTypes.arrayOf(ExperienceDataPropType),
  formData: AgamiSpotPropType,
  setFormData: PropTypes.func,
};

export default AgamiExperienceSelector;

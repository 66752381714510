import React, {
  useContext,
  useState,
  createContext,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import {
  getSupabaseSession,
  getUserDefaultExperienceId,
  getUserProfile,
  getUserSubscriptions,
} from '../utils/helpers/supabase.helpers';

const SessionContext = createContext({
  refresh: () => { },
  loading: true,
  user: undefined,
});

export const useSessionContext = () => useContext(SessionContext);

export const SessionContextProvider = ({ children }) => {
  const [user, setUser] = useState();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const refetchUser = useCallback(async () => {
    setLoading(true);

    const { data: session } = await getSupabaseSession();
    if (!session) {
      setUser(undefined);
      setLoading(false);
      return;
    }

    const { data: profile } = await getUserProfile();
    if (!profile) {
      setUser(undefined);
      Sentry.setUser(null);
      setLoading(false);
      return;
    }

    const { data: subs } = await getUserSubscriptions({ userId: profile.id });

    const subscription = Array.isArray(subs) && subs?.length > 0 ? subs[0] : null;

    const { id: defaultExperienceId } = await getUserDefaultExperienceId(profile.id);

    // we can only set ID here for privacy compliances
    Sentry.setUser({
      id: session.user?.id,
    });

    setUser({
      id: session.user?.id,
      completeName: profile.name ? `${profile?.name || ''} ${profile?.surname || ''}` : undefined,
      email: session.user?.email,
      confirmedAt: session.user?.confirmed_at,
      role: profile.roles?.role_name,
      defaultExperienceId,
      ...(subscription && { subscription }),
      ...profile,
    });

    setLoading(false);
  }, []);

  const refresh = () => {
    refetchUser();
    navigate('/');
  };

  useEffect(() => {
    refetchUser();
  }, [refetchUser]);

  const contextValue = useMemo(
    () => ({
      user,
      loading,
      refresh,
    }),
    [
      user,
      loading,
      refresh,
    ],
  );

  return (
    <SessionContext.Provider value={contextValue}>
      {children}
    </SessionContext.Provider>
  );
};

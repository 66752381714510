import { FEATURES } from './features.constants';

// possible roles (see also DB table)
export const ROLES = {
  ADMIN: 'admin',
  VENUE: 'venue',
  CONTENT_CREATOR: 'content_creator',
  EARLY_BIRD: 'early_bird',
  FREE: 'free',
};

const FREE = [
  FEATURES.UPLOAD_SPOTS_UI,
  FEATURES.SOUND_MAP,
];

const EARLY_BIRD = [
  FEATURES.UPLOAD_SPOTS_UI,
  FEATURES.SOUND_MAP,
];

const CONTENT_CREATOR = [
  ...FREE,
  FEATURES.SOUND_MAP,
  FEATURES.SHOWCASE_UI,
];

const VENUE = [
  ...FREE,
  FEATURES.EXP_EDITOR_UI,
  FEATURES.MANUAL_SPOTS,
  FEATURES.EXP_ACCESS_CODES,
];

const ADMIN = [
  ...VENUE,
  FEATURES.SHOWCASE_UI,
  FEATURES.ADMIN_PANEL,
  FEATURES.DOWNLOAD_EXPERIENCE,
];

export const PLANS = {
  [ROLES.ADMIN]: ADMIN,
  [ROLES.VENUE]: VENUE,
  [ROLES.CONTENT_CREATOR]: CONTENT_CREATOR,
  [ROLES.EARLY_BIRD]: EARLY_BIRD,
  [ROLES.FREE]: FREE,
};

export const UPLOAD_SPOT_LIMIT = {
  [ROLES.ADMIN]: Infinity,
  [ROLES.VENUE]: Infinity,
  [ROLES.CONTENT_CREATOR]: Infinity,
  [ROLES.EARLY_BIRD]: 10,
  [ROLES.FREE]: 1,
};

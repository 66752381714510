import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { LinkPropType } from '../PropTypes';

const Sidebar = ({ links }) => {
  const { pathname } = useLocation();
  return (
    <div
      className="h-full w-[60px] md:w-[180px] lg:w-[240px] pt-8 md:pt-14 px-3 lg:px-6 pb-4"
    >
      <div className="flex flex-col justify-center items-start gap-8">
        {links?.map((link) => {
          const linkColor = (link.activeLinkCondition || pathname === link.to)
            ? 'text-accent'
            : 'text-white-yellow';
          return (
            <Link
              className={`hover:opacity-50 ${linkColor}`}
              key={`${link.to}`}
              to={link.to}
            >
              <div className="flex items-center justify-center gap-2">
                {link.Icon ? <link.Icon className="w-6 h-6" /> : null}
                <span className="hidden md:inline-block">{link.label}</span>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  links: PropTypes.arrayOf(LinkPropType),
};

export default Sidebar;

export const getRoleLabel = (role) => {
  switch (role) {
    case 'admin':
    case 1:
      return 'ADMIN';
    case 'venue':
    case 2:
      return 'VENUE';
    case 'content_creator':
    case 4:
      return 'CONTENT CREATOR';
    case 'early_bird':
    case 3:
      return 'EARLY BIRD';
    case 'free':
    case 5:
    default:
      return 'FREE';
  }
};

export const getRoleColor = (role) => {
  switch (role) {
    case 'admin':
    case 1:
      return 'amber';
    case 'venue':
    case 2:
      return 'purple';
    case 'content_creator':
    case 4:
      return 'teal';
    case 'early_bird':
    case 3:
      return 'indigo';
    case 'free':
    case 5:
    default:
      return 'cyan';
  }
};

export const getRoleId = (role) => {
  switch (role) {
    case 'admin':
      return 1;
    case 'venue':
      return 2;
    case 'early_bird':
      return 3;
    case 'content_creator':
      return 4;
    case 'free':
    default:
      return 5;
  }
};

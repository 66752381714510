import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useGoBack = (options) => {
  const fallbackRoute = options?.fallback ?? '/';
  const navigate = useNavigate();
  const { key } = useLocation();
  return useCallback(() => {
    if (key === 'default') {
      navigate(fallbackRoute, { replace: true });
    } else {
      navigate(-1);
    }
  }, [fallbackRoute]);
};

import React from 'react';
import { Link } from 'react-router-dom';
import posthog from 'posthog-js';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import { useMountEffect } from '../hooks/toolkit';
import { theme } from '../tailwind.config';

const { colors } = theme.extend;
const PRIMARY = colors.primary;
const ACCENT = colors.accent;
const ACCENT_RED = colors['accent-red'];

const COOKIE_NAME = 'AgamiCookieConsent';

const CookieConsentBanner = () => {
  const acceptCookies = () => {
    posthog.opt_in_capturing();
  };

  const declineCookies = () => {
    console.info('declined!');
    posthog.opt_out_capturing();
  };

  useMountEffect(() => {
    const savedVal = getCookieConsentValue(COOKIE_NAME);
    if (savedVal === 'true') posthog.opt_in_capturing();
    else posthog.opt_out_capturing();
  });

  return (
    <CookieConsent
      location="bottom"
      cookieName={COOKIE_NAME}
      enableDeclineButton
      onAccept={acceptCookies}
      onDecline={declineCookies}
      style={{
        background: ACCENT.DEFAULT,
        color: PRIMARY.DEFAULT,
      }}
      buttonStyle={{
        background: PRIMARY.DEFAULT,
        borderRadius: 10,
        color: '#FFF',
        fontSize: '.821rem',
      }}
      declineButtonStyle={{
        background: ACCENT_RED.DEFAULT,
        borderRadius: 10,
        color: '#FFF',
        fontSize: '.821rem',
      }}
      expires={90}
    >
      This website uses cookies to enhance the user experience. You can check the details in our
      {' '}
      <Link to="/privacy-policy" className="underline font-bold">privacy policy</Link>
      .
    </CookieConsent>
  );
};

export default CookieConsentBanner;

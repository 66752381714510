import React, { useState } from 'react';
import {
  Chip,
  Tooltip,
  Typography,
} from '@material-tailwind/react';
import { Link } from 'react-router-dom';
import { useMountEffect, useScreenBreakpoint } from '../hooks/toolkit';
import { getExperienceSpotsCount } from '../utils/helpers/supabase.helpers';
import { useFeatureAuth } from '../hooks/authorization';
import { FEATURES } from '../utils/constants/features.constants';
import DownloadExpButton from './DownloadExpButton';
import Avatar from '../components/Avatar';
import { ExperienceCardPropType } from '../PropTypes';

const ExperienceCard = ({
  isActive,
  experienceId,
  name,
  owner,
  isLibrary,
  isSelected,
}) => {
  const canDownloadExp = useFeatureAuth(FEATURES.DOWNLOAD_EXPERIENCE);

  const [spotsCount, setSpotsCount] = useState(null);

  const expOwner = owner
    ? `${owner.name} ${owner.surname}`
    : null;

  const getCount = async () => {
    const { count, error } = await getExperienceSpotsCount({ experienceId });

    if (error) {
      console.error(error);
      return;
    }

    setSpotsCount(count);
  };

  useMountEffect(() => {
    getCount();
  });

  const screen = useScreenBreakpoint();

  const smallScreen = screen === 'sm' || screen === 'md';

  return (
    <Link
      to={`/experience/${experienceId}`}
      className={`
        flex items-center space-x-4 p-2 rounded
        px-4
        ${isSelected ? 'bg-white-yellow' : 'bg-white'}
        ${isActive ? 'opacity-100 hover:bg-base-gray-500 cursor-pointer' : 'opacity-50 pointer-events-none'}
      `}
    >
      <div className="w-full flex gap-4 items-center justify-between ">

        <div className="w-full flex gap-2 items-center">
          {isSelected && <div className="w-4 h-4 rounded-full bg-accent shadow-lg" />}
          <div className="hidden md:block">
            <Avatar
              size={smallScreen ? 'md' : 'lg'}
              name={name}
              surname={name.split(' ')[1]}
            />
          </div>
          <div className="flex-shrink-0">
            <Typography
              variant={smallScreen ? 'h6' : 'h5'}
              className="font-bold"
            >
              {name}
            </Typography>
            <Typography variant="small">
              by
              {' '}
              <strong>{expOwner || 'MezzoForte'}</strong>
              {' '}
              |
              {' '}
              <strong>{spotsCount}</strong>
              {' '}
              agami
            </Typography>
            {isLibrary && (
              <Tooltip
                content="This experience collects all content you uploaded"
                placement="right"
              >
                <Chip
                  value="Your library"
                  color="accent"
                  size="sm"
                  className="inline-block"
                />
              </Tooltip>
            )}
          </div>
        </div>

        {isActive && canDownloadExp && (
          <DownloadExpButton experienceId={experienceId} />
        )}
      </div>
    </Link>
  );
};

ExperienceCard.propTypes = ExperienceCardPropType;

export default ExperienceCard;

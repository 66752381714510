export const FEATURES = {
  ADMIN_PANEL: 'admin_panel',
  UPLOAD_SPOTS_UI: 'upload_spots_ui',
  EXP_EDITOR_UI: 'experience_editor_ui',
  SOUND_MAP: 'sound_map',
  SHOWCASE_UI: 'showcase_ui',
  DOWNLOAD_EXPERIENCE: 'download_experience',
  MANUAL_SPOTS: 'manual_spots',
  EXP_ACCESS_CODES: 'experience_access_codes',
};

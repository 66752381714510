import React from 'react';

import { createRoot } from 'react-dom/client';

import * as Sentry from '@sentry/react';

import posthog from 'posthog-js';

import { toast } from 'react-toastify';

import * as serviceWorker from './serviceWorker';

import App from './App';

import pkgInfo from '../package.json';

import { SENTRY_DSN_URL, POSTHOG_KEY, POSTHOG_API_HOST } from './utils/constants/services.constants';

import './style/fonts.css';

import './style/index.css';

import './style/privacy-policy.css';

import 'leaflet/dist/leaflet.css';

import 'react-toastify/dist/ReactToastify.css';

import 'mapbox-gl/dist/mapbox-gl.css';

const { version } = pkgInfo;

posthog.init(POSTHOG_KEY, { api_host: POSTHOG_API_HOST });

Sentry.init({
  release: version,
  dsn: SENTRY_DSN_URL,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  /**
   * This sets the sample rate at 10%.
   * You may want to change it to 100%
   * while in development and then sample at a lower rate in production.
   */
  replaysSessionSampleRate: 0.1,
  /**
   * If you're not already sampling the entire session,
   * change the sample rate to 100% when sampling sessions where errors occur.
   */
  replaysOnErrorSampleRate: 1.0,
});

const container = document.getElementById('root');

const root = createRoot(container);

root.render(
  <App />,
);

serviceWorker.unregister({
  onSuccess: () => {
    toast.success('Welcome to Agami!', {
      containerId: 'general-toast-container',
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      style: {
        backgroundColor: '#21323D',
        color: '#FAE632',
      },
    });
  },
  onUpdate: () => {
    toast.info('A new version is available. Close and reopen the app to update', {
      containerId: 'general-toast-container',
      position: 'bottom-center',
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      style: {
        backgroundColor: '#FAE632',
        color: '#21323D',
      },
    });
  },
});

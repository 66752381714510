import React, {
  useState,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Link, useSearchParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import {
  Alert,
  Input,
  Typography,
  Button,
  Spinner,
  Textarea,
} from '@material-tailwind/react';
import { useSessionContext } from '../contexts/SessionContext';
import { cleanFileName, upsertAgamiSpot } from '../utils/helpers/upload.helpers';
import SpotPositionSelector from './SpotPositionSelector';
import SpotManualCodeSelector from './SpotManualCodeSelector';
import SpotPositionPreview from '../components/SpotPositionPreview';
import { AGAMI_GLOBAL_EXPERIENCE_ID } from '../utils/constants/db.constants';
import { useGeneralToast } from '../hooks/toast';
import AudioFileDropzone from './AudioFileDropzone';
import { useFeatureAuth } from '../hooks/authorization';
import { useFormData } from '../hooks/form';
import { FEATURES } from '../utils/constants/features.constants';
import AgamiTypeSelector from './AgamiTypeSelector';
import AgamiVisibilitySelector from './AgamiVisibilitySelector';
import AgamiExperienceSelector from './AgamiExperienceSelector';
import { useUserExperiences } from '../hooks/agami';
import { removeManualCodeLibrarySuffix } from '../utils/helpers/agami.helpers';
import { useGoBack } from '../hooks/router';
import { AgamiSpotDetailPropType } from '../PropTypes';

const UploadAgamiInterface = ({
  uploadSizeLimit,
  allowedFileTypes,
  availableSpotTypes,
  spot,
}) => {
  const { user } = useSessionContext();
  const { error: toastError, success: toastSuccess } = useGeneralToast();
  const [audioFile, setAudioFile] = useState(null);
  const goBack = useGoBack();

  const manualSpotsAuth = useFeatureAuth(FEATURES.MANUAL_SPOTS);
  const { experienceList: userExperiences } = useUserExperiences({
    excludeDefaultExperience: true,
  });

  const existingSpotEdit = useMemo(() => !!spot, [spot]);

  const [searchParams] = useSearchParams();
  const addToExperience = searchParams.get('addToExperience');

  const initialFormData = useMemo(() => {
    // if editing an existing spot
    if (existingSpotEdit && spot) {
      return {
        experiences: spot.experience_content_spots?.map((exp) => exp.experience_id),
        spotName: spot.name,
        description: spot.description,
        latitude: spot.latitude,
        longitude: spot.longitude,
        radius: spot.radius,
        deadband: spot.deadband,
        type: spot.type,
        manual_code: removeManualCodeLibrarySuffix(spot.experience_content_spots[0]?.manual_code),
      };
    }
    // if creating a new spot
    const experiences = addToExperience
      ? [addToExperience, user.defaultExperienceId]
      : [user.defaultExperienceId];

    return {
      experiences,
      spotName: '',
      description: '',
      latitude: '',
      longitude: '',
      radius: '',
      deadband: '',
      type: 1,
      manual_code: '',
    };
  }, [existingSpotEdit, spot, addToExperience]);

  const {
    formData,
    setFormData,
    handleDataChange,
    checkInputIsValid,
  } = useFormData(initialFormData);

  // if user has a private library, give the chance to chose between public and private
  const showMakePublicOption = user.defaultExperienceId !== AGAMI_GLOBAL_EXPERIENCE_ID;

  // already in global exp (so already public)?
  const isInGlobalExp = spot
    ?.experience_content_spots
    ?.map((exp) => exp.experience_id)
    ?.includes(AGAMI_GLOBAL_EXPERIENCE_ID);

  const [isPublic, setIsPublic] = useState(!showMakePublicOption || isInGlobalExp);
  const [uploading, setUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);

  useEffect(() => {
    if (isPublic && !formData.experiences.includes(AGAMI_GLOBAL_EXPERIENCE_ID)) {
      handleDataChange(
        'experiences',
        (currExps) => [...currExps, AGAMI_GLOBAL_EXPERIENCE_ID],
      );
    }

    if (!isPublic && formData.experiences.includes(AGAMI_GLOBAL_EXPERIENCE_ID)) {
      handleDataChange(
        'experiences',
        (currExps) => currExps.filter((e) => e !== AGAMI_GLOBAL_EXPERIENCE_ID),
      );
    }
  }, [isPublic, formData.experiences]);

  const confirmUpload = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!existingSpotEdit && !audioFile) {
      toastError('You need to upload an audio file!');
      return;
    }

    if (isEmpty(formData)) {
      return;
    }

    setUploading(true);

    const audioFilename = cleanFileName({ audioFile, userId: user.id });

    upsertAgamiSpot({
      ...formData,
      userDefaultExpId: user.defaultExperienceId,
      spotId: spot?.id,
      audioContentPath: spot?.audio_content_path,
      spotName: formData.spotName.trim(),
      userId: user.id,
      audioFile,
      audioFilename,
      onSuccess: (data) => {
        console.info('Upload successful', { data });
        setUploading(false);
        setUploadComplete(true);
        toastSuccess('Upload successfu!');
        goBack();
      },
      onError: (error) => {
        console.error(error);
        toastError(`Error uploading your spot: ${error.message}`);
        setUploading(false);
      },
    });
  };

  const submitButtonDisabled = (
    uploading
    || (formData.type === 2 && !formData.manual_code)
  );

  return (
    <div className="w-full md:w-1/2 lg:w-2/3 my-10">

      {uploadComplete && (
        <Alert color="green" variant="ghost">
          <Typography>
            Upload completed! Go to your
            {' '}
            <span className="font-bold underline">
              <Link to="/">dashboard</Link>
              .
            </span>
          </Typography>
        </Alert>
      )}

      {!uploadComplete && (
        <form
          className="flex flex-col gap-4 px-3 md:px-0"
          onSubmit={confirmUpload}
        >
          <AudioFileDropzone
            spot={spot}
            uploadSizeLimit={uploadSizeLimit}
            allowedFileTypes={allowedFileTypes}
            audioFile={audioFile}
            setAudioFile={setAudioFile}
            existingSpotEdit={existingSpotEdit}
          />

          <div className="mt-1" />

          <Typography
            className="font-bold text-primary"
          >
            Agami Metadata
          </Typography>

          <Input
            className="w-full"
            value={formData.spotName}
            onChange={(e) => handleDataChange('spotName', e.currentTarget.value)}
            type="text"
            label="Name (e.g. 'My awesome spot')"
            required
            disabled={uploading}
          />

          <Textarea
            className="w-full"
            rows={1}
            value={formData.description}
            onChange={(e) => handleDataChange('description', e.currentTarget.value)}
            type="text"
            label="Description (optional)"
            disabled={uploading}
          />

          {userExperiences?.length > 0 && (
            <>
              <div className="mt-1" />
              <AgamiExperienceSelector
                userExperiences={userExperiences}
                formData={formData}
                setFormData={setFormData}
              />
            </>
          )}

          {showMakePublicOption && (
            <>
              <div className="mt-1" />
              <AgamiVisibilitySelector
                isPublic={isPublic}
                setIsPublic={setIsPublic}
                formData={formData}
              />
            </>
          )}

          {manualSpotsAuth && (
            <>
              <div className="mt-1" />
              <AgamiTypeSelector
                isPublic={isPublic}
                formData={formData}
                handleDataChange={handleDataChange}
                availableSpotTypes={availableSpotTypes}
              />
            </>
          )}

          {/* GEO TYPE */}
          {formData.type === 1 && (
            <>
              <div className="mt-1" />
              <SpotPositionSelector
                formData={formData}
                setFormData={setFormData}
                handleDataChange={handleDataChange}
                checkInputIsValid={checkInputIsValid}
                uploading={uploading}
              />
              <SpotPositionPreview
                interactive
                latitude={formData.latitude}
                setLatitude={(lat) => handleDataChange('latitude', lat)}
                longitude={formData.longitude}
                setLongitude={(lon) => handleDataChange('longitude', lon)}
                radius={formData.radius}
                deadband={formData.deadband}
              />
            </>
          )}

          {/* MANUAL TYPE */}
          {formData.type === 2 && (
            <>
              <div className="mt-1" />
              <SpotManualCodeSelector
                formData={formData}
                uploading={uploading}
                handleDataChange={handleDataChange}
                spotId={spot?.id}
              />
            </>
          )}

          <Button
            type="submit"
            className="mt-8"
            disabled={submitButtonDisabled}
          >
            {uploading ? <Spinner className="h-4 w-4 mx-auto" /> : 'CONFIRM'}
          </Button>
        </form>
      )}
    </div>
  );
};

UploadAgamiInterface.propTypes = {
  spot: AgamiSpotDetailPropType,
  uploadSizeLimit: PropTypes.number,
  availableSpotTypes: PropTypes.shape({
    [PropTypes.number]: PropTypes.string,
  }),
  allowedFileTypes: PropTypes.arrayOf(PropTypes.string),
};

export default UploadAgamiInterface;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Spinner,
  Typography,
  Button,
  Card,
  CardBody,
  IconButton,
  Input,
  Switch,
} from '@material-tailwind/react';
import { PiX } from 'react-icons/pi';
import ShareExperiencePanel from '../components/ShareExperiencePanel';
import { ExperienceDataPropType } from '../PropTypes';
import { useDeepCompareObjectsEffect } from '../hooks/toolkit';
import { updateExperienceDetails } from '../utils/helpers/upload.helpers';
import { validateInputNumber } from '../utils/helpers/validate.helpers';
import { useGeneralToast } from '../hooks/toast';
import { useFormData } from '../hooks/form';

const ExperienceSidebar = ({
  experienceDetails,
  refetchExperienceDetails,
  closeSidebar,
  sidebarIsClosed,
  isInBottomSheet,
}) => {
  const { success: toastSuccess, error: toastError } = useGeneralToast();
  const [loading, setLoading] = useState(false);
  const {
    formData,
    setFormData,
    handleDataChange,
    checkInputIsValid,
  } = useFormData(experienceDetails);
  const [changedData] = useDeepCompareObjectsEffect(experienceDetails, formData);

  // reset form when sidebar is closed
  useEffect(() => {
    if (sidebarIsClosed) {
      setFormData(experienceDetails);
    }
  }, [sidebarIsClosed]);

  const submitChanges = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);

    const {
      id,
      default_radius,
      default_deadband,
      fade_in_time,
      fade_out_time,
      accuracy,
      spots_replay,
      spots_overlap,
      enable_high_accuracy,
    } = formData;

    const { error } = await updateExperienceDetails({
      expNewData: {
        id,
        default_radius,
        default_deadband,
        fade_in_time,
        fade_out_time,
        accuracy,
        spots_replay,
        spots_overlap,
        enable_high_accuracy,
      },
    });

    if (error) {
      toastError('Something went wrong, try again!');
      console.error(error);
      setLoading(false);
      return;
    }

    await refetchExperienceDetails();
    setLoading(false);
    toastSuccess('Experience details succesfully updated');
  };

  return (

    <Card className="w-full h-full">
      <CardBody>

        {!isInBottomSheet && (
          <div className="absolute px-4 top-0 right-0">
            <IconButton
              size="sm"
              variant="text"
              color="gray"
              onClick={closeSidebar}
            >
              <PiX className="h-5 w-5 text-primary" />
            </IconButton>
          </div>
        )}

        <div className="mb-10">
          {!isInBottomSheet && (
            <Typography variant="h5">
              SETTINGS
            </Typography>
          )}

          <form
            className="flex flex-col gap-3 my-4"
            onSubmit={submitChanges}
          >
            <Typography>
              Created
              {' '}
              <strong>{new Date(formData.created_at).toLocaleDateString('en-GB')}</strong>
            </Typography>

            <Input
              type="number"
              label="Agami radius size (m)"
              min={0}
              value={formData.default_radius ?? ''}
              onChange={
                (e) => handleDataChange('default_radius', validateInputNumber(e.currentTarget.value ?? ''))
              }
              onBlur={() => checkInputIsValid('default_radius', formData.default_radius > 0)}
            />

            <Input
              type="number"
              label="Agami deadband size (m)"
              min={0}
              value={formData.default_deadband ?? ''}
              onChange={
                (e) => handleDataChange('default_deadband', validateInputNumber(e.currentTarget.value ?? ''))
              }
              onBlur={() => checkInputIsValid('default_deadband', formData.default_deadband > 0)}
            />

            <Input
              type="number"
              label="Fade in time (ms)"
              min={0}
              value={formData.fade_in_time ?? ''}
              onChange={
                (e) => handleDataChange('fade_in_time', validateInputNumber(e.currentTarget.value ?? ''))
              }
              onBlur={() => checkInputIsValid('fade_in_time', formData.fade_in_time >= 0)}
            />

            <Input
              type="number"
              label="Fade out time (ms)"
              value={formData.fade_out_time ?? ''}
              min={0}
              onChange={
                (e) => handleDataChange('fade_out_time', validateInputNumber(e.currentTarget.value ?? ''))
              }
              onBlur={() => checkInputIsValid('fade_out_time', formData.fade_out_time >= 0)}
            />

            <Input
              type="number"
              label="Min required pos accuracy (m)"
              min={1}
              value={formData.accuracy ?? ''}
              onChange={
                (e) => handleDataChange('accuracy', validateInputNumber(e.currentTarget.value ?? ''))
              }
              onBlur={() => checkInputIsValid('accuracy', formData.accuracy > 0)}
            />

            <Switch
              id="spots_replay_switch"
              label="Spots replay"
              checked={Boolean(formData.spots_replay)}
              onChange={(e) => handleDataChange('spots_replay', e.currentTarget.checked)}
            />

            <Switch
              id="spots_overlap_switch"
              label="Spots overlap"
              checked={Boolean(formData.spots_overlap)}
              onChange={(e) => handleDataChange('spots_overlap', e.currentTarget.checked)}
            />

            <Switch
              id="high_accuracy_switch"
              label="High accuracy"
              checked={Boolean(formData.enable_high_accuracy)}
              onChange={(e) => handleDataChange('enable_high_accuracy', e.currentTarget.checked)}
            />

            {changedData && (
              <Button
                type="submit"
                size="sm"
                disabled={loading}
              >
                {loading ? <Spinner className="h-4 w-4 mx-auto" /> : 'SAVE CHANGES'}
              </Button>
            )}
          </form>
        </div>

        {/* sharing */}
        <div className="mt-4">
          <ShareExperiencePanel
            experience={experienceDetails}
            onSuccess={refetchExperienceDetails}
          />
        </div>
      </CardBody>
    </Card>
  );
};

ExperienceSidebar.propTypes = {
  experienceDetails: ExperienceDataPropType,
  refetchExperienceDetails: PropTypes.func,
  closeSidebar: PropTypes.func,
  sidebarIsClosed: PropTypes.bool,
  isInBottomSheet: PropTypes.bool,
};

export default ExperienceSidebar;

import React, { useEffect, useState } from 'react';
import { IconButton, Typography } from '@material-tailwind/react';
import { useParams } from 'react-router-dom';
import { BiChevronLeft } from 'react-icons/bi';
import { useMountEffect, usePageTitle } from '../hooks/toolkit';
import { getUserExperiences } from '../utils/helpers/supabase.helpers';
import ExperienceCard from '../ViewExperience/ExperienceCard';
import HeaderSpace from '../layout/HeaderSpace';
import SoundMap from './SoundMap';
import { useSessionContext } from '../contexts/SessionContext';
import { useMapControls } from '../contexts/MapControlsContext';
import illustration from '../assets/png/agami_experience.png';

const Experience = () => {
  usePageTitle('Experience | Agami');

  const { experienceId } = useParams();
  const { user } = useSessionContext();
  const {
    drawer,
    setDrawer,
    drawerForced,
    setDrawerForced,
  } = useMapControls();

  const [experiences, setExperiences] = useState('loading');

  const fetchExps = async () => {
    const userId = user.id;

    const { data, error } = await getUserExperiences({ userId });

    if (error) {
      console.error(error);
      setExperiences(null);
      return;
    }

    setExperiences(data);
  };

  useEffect(() => {
    if (experienceId) {
      setDrawerForced(false);
      setDrawer(false);
    } else {
      setDrawerForced(true);
    }
  }, [experienceId]);

  useMountEffect(() => {
    fetchExps();
  });

  const showDrawer = drawerForced || drawer;

  return (
    <div className="w-full h-full min-h-screen relative">
      <div className={`
        absolute z-40 h-full top-0 overflow-scroll
        transition-all ease-in-out ${showDrawer ? 'w-full md:w-[70%] lg:w-[42%] px-6 md:px-12' : 'w-0 px-0'}
        py-8 bg-white
        shadow-lg shadow-[#21323D60]
      `}
      >
        <HeaderSpace />

        <div className="w-full">

          <div className="flex w-full justify-between items-start pb-4">

            <div className="flex flex-col">
              <Typography variant="h1">
                EXPERIENCES
              </Typography>
              {experiences !== 'loading' && experiences?.length > 0 && (
                <Typography className="text-primary font-bold">
                  You have
                  {' '}
                  {experiences?.length}
                  {' '}
                  experiences.
                  Choose one to load the map!
                </Typography>
              )}
            </div>

            {(!drawerForced && drawer) && (
              <IconButton
                className="text-primary"
                onClick={() => setDrawer(false)}
                variant="text"
                color="gray"

              >
                <BiChevronLeft className="h-6 w-6" />
              </IconButton>
            )}
          </div>

          {experiences === 'loading' && (<Typography>Loading...</Typography>)}

          {experiences !== 'loading' && experiences?.length === 0 && (
            <Typography>You don`&apos;t have any experiences yet</Typography>
          )}

          <div className="flex flex-col h-full overflow-scroll">
            {experiences !== 'loading' && experiences?.length > 0 && experiences.map((exp) => (
              <ExperienceCard
                key={exp.experience_id}
                isActive={exp.experiences?.is_active}
                date={exp.created_at}
                experienceId={exp.experience_id}
                name={exp.experiences?.name}
                owner={exp.experiences?.profiles}
                isLibrary={exp.experiences?.is_default && exp.experiences.owner_id === user.id}
                isSelected={experienceId === exp.experience_id}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="md:block w-full h-full">
        {!experienceId ? (
          <div className="ml-auto md:w-[65%] pr-12 h-full flex flex-col items-center justify-center bg-white">
            <img
              src={illustration}
              className="w-[50%]"
              alt="Agami Experience"
              title="Agami Experience"
            />
          </div>
        ) : (
          <SoundMap experienceId={experienceId} />
        )}
      </div>
    </div>
  );
};

export default Experience;

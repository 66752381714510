const streamToText = async (readableStream) => {
  const reader = readableStream.getReader();
  const decoder = new TextDecoder('utf-8');
  let text = '';
  // eslint-disable-next-line no-constant-condition
  while (true) {
    // eslint-disable-next-line no-await-in-loop
    const { done, value } = await reader.read();
    if (done) {
      break;
    }
    text += decoder.decode(value);
  }
  return text;
};

const textToJson = (text) => {
  try {
    return JSON.parse(text);
  } catch (error) {
    throw new Error(`Failed to parse text as JSON: ${error.message}`);
  }
};

export const processStream = async (readableStream) => {
  try {
    const text = await streamToText(readableStream);
    return textToJson(text);
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};

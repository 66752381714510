import React from 'react';
import { Input, Typography } from '@material-tailwind/react';
import { useSessionContext } from '../contexts/SessionContext';

const Profile = () => {
  const { user } = useSessionContext();
  return (
    <div className="w-11/12 mx-auto mt-12">

      <Typography
        variant="h1"
        className="text-primary"
      >
        PROFILE
      </Typography>

      <div className="grid grid-cols-2 gap-6 py-10 w-full md:w-1/2">
        <div className="col-span-2">
          <Input
            value={user?.email}
            size="lg"
            readOnly
            type="email"
            label="Email"
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <Input
            value={user?.name}
            size="lg"
            readOnly
            type="text"
            label="Name"
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <Input
            value={user?.surname}
            size="lg"
            readOnly
            type="text"
            label="Surname"
          />
        </div>
        <div className="col-span-2">
          <Input
            value={user?.phone}
            size="lg"
            readOnly
            type="phone"
            label="Phone"
          />
        </div>
        <div className="col-span-2">
          <Input
            value={user?.company}
            size="lg"
            readOnly
            type="text"
            label="Company/organization/institute"
          />
        </div>
        <div className="col-span-2">
          <Input
            value={user?.bch_model}
            size="lg"
            readOnly
            type="text"
            label="Your BCH"
          />
        </div>
      </div>
    </div>
  );
};

export default Profile;

import React from 'react';
import { Typography } from '@material-tailwind/react';
import GoBack from '../components/GoBack';

const UserList = () => (
  <div className="my-12 max-w-5xl mx-auto">
    <div className="mb-2 self-start">
      <GoBack />
    </div>
    <Typography variant="lead">Users List</Typography>
  </div>
);

export default UserList;

import { Typography, Button } from '@material-tailwind/react';
import React from 'react';
import { Link } from 'react-router-dom';

const FinalizeUserTitle = ({ userType }) => (

  <div
    className={`${userType ? 'mb-0' : 'mb-8'} flex flex-col justify-center items-center`}
  >
    <Typography
      className="mt-4"
      variant="h1"
      color="white"
    >
      FINALIZE ACCOUNT
    </Typography>

    {userType && <hr className="w-full md:w-1/4 my-6 bg-accent border-accent" />}

    {(userType === 'indiegogo') && (
      <Typography
        variant="h3"
        className="text-accent"
      >
        INDIEGOGO EARLY BIRD SUPPORTER
      </Typography>
    )}

    {(userType === 'bch-owner') && (
      <Typography
        variant="h3"
        className="text-accent"
      >
        BCH OWNER
      </Typography>
    )}

    {(userType === 'profile-only') && (
      <>
        <Typography
          variant="h3"
          className="text-accent mb-2"
        >
          PROFILE ONLY
        </Typography>
        <Typography
          variant="paragraph"
          className="text-accent font-bold"
        >
          This platform works best with Bone conduction headphones
        </Typography>
        <Typography
          variant="paragraph"
          color="white"
        >
          Are you sure you don&apos;t want to buy Mezzoforte Bone Conduction Headphones?
        </Typography>
        <Link
          className="mt-2"
          to="https://www.indiegogo.com/projects/agami-everywhere-immersive-sound"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            size="sm"
          >
            Buy now
          </Button>
        </Link>
      </>
    )}

  </div>
);

export default FinalizeUserTitle;

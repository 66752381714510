import React from 'react';
import { Button, Typography } from '@material-tailwind/react';
import { useNavigate } from 'react-router-dom';
import Container from '../components/Container';
import Divider from '../components/Divider';
import { usePageTitle } from '../hooks/toolkit';
import illustration from '../assets/png/how-it-works/how_it_works_illustr.png';
import step1 from '../assets/png/how-it-works/step-1.png';
import step2 from '../assets/png/how-it-works/step-2.png';
import step3 from '../assets/png/how-it-works/step-3.png';

const HowItWorks = () => {
  usePageTitle('How it works | Agami');

  const navigate = useNavigate();

  return (
    <Container
      direction="col"
      bgColorClass="bg-white"
      className="text-primary"
    >

      <div
        className="flex text-center flex-col justify-center items-center gap-4 py-4 lg:py-10 px-2 lg:px-32"
      >

        <img
          className="w-1/2 lg:w-1/3"
          src={illustration}
          alt="how agami works?"
          title="how agami works?"
        />

        <Typography
          variant="h1"
        >
          How it works
        </Typography>

        <Typography>
          <strong>agami</strong>
          {' '}
          is an audio platform to create, organize and
          manage immersive geolocated experiences.
          Start now exploring awesome sound maps
          and uploading your own content!
        </Typography>

        <Typography variant="h5">
          <strong>It&apos;s easy, like a sound-walk in the park :)</strong>
        </Typography>
      </div>

      <Divider color="dark" />

      <div className="grid grid-cols-2 gap-8 py-4 lg:py-10">

        <div className="flex flex-col gap-4 col-span-2 lg:col-span-1 items-center lg:items-start">

          <Typography variant="h2">
            Upload an
            {' '}
            agami
          </Typography>

          <img
            src={step1}
            className="block lg:hidden w-full border rounded shadow col-span-2 lg:col-span-1"
            title="upload an agami"
            alt="upload an agami"
          />

          <Typography>
            An
            {' '}
            agami
            {' '}
            is
            {' '}
            <strong>a sound associated with a physical location</strong>
            .
            With the Free plan, the
            {' '}
            agami
            {' '}
            you upload are automatically
            displayed on the Global experience.
            Upload a sound file (less than 30 Mb) together with:
          </Typography>

          <Typography>
            <ul className="pl-8 list-disc">
              <li>
                The
                {' '}
                <strong>Name</strong>
                {' '}
                that will be displayed for it on the Global experience
              </li>
              <li>
                The
                {' '}
                <strong>Description</strong>
                {' '}
                that will be displayed: describe your soundwork,
                the recording conditions, and credits for artworks
                or venues it may refer to
              </li>
              <li>
                <strong>GPS coordinates </strong>
                of the place of interest where the
                soundwork will be played, by choosing on the map or specifying
                Latitude and Longitude (e.g. by taking them from an external
                map service, such as Google Maps)
              </li>
              <li>
                The
                {' '}
                <strong>Radius (in meters)</strong>
                {' '}
                indicating how far
                from the place of interest the soundwork will start playing
              </li>
            </ul>
          </Typography>

          <Typography>
            Then,
            {' '}
            <strong>hit CONFIRM to share your soundwork</strong>
            {' '}
            with everyone getting to that place of interest.
          </Typography>

          <Button
            size="sm"
            onClick={() => navigate('/upload/spot')}
          >
            TRY THE UPLOAD INTERFACE
          </Button>

        </div>

        <img
          src={step1}
          className="hidden lg:block w-full border rounded shadow col-span-2 lg:col-span-1"
          title="upload an agami"
          alt="upload an agami"
        />
      </div>

      <Divider color="dark" />

      <div className="grid grid-cols-2 gap-8 py-4 lg:py-10 justify-center">
        <img
          src={step2}
          className="hidden lg:block w-1/2 justify-self-center col-span-2 lg:col-span-1"
          title="explore agami map"
          alt="explore agami map"
        />

        <div className="flex flex-col gap-4 col-span-2 lg:col-span-1 items-center lg:items-start">
          <Typography variant="h2">
            Explore agami
          </Typography>

          <img
            src={step2}
            className="block lg:hidden w-1/2 justify-self-center col-span-2 lg:col-span-1"
            title="explore agami map"
            alt="explore agami map"
          />

          <Typography>
            When you select an experience,
            {' '}
            <strong>agami</strong>
            {' '}
            will ask for
            an authorization to access the GPS localization
            of your device. This is needed to
            {' '}
            <strong>
              automatically
              trigger a soundwork
            </strong>
            {' '}
            whenever you get close to it,
            with no further interaction needed.
            Each
            {' '}
            agami
            {' '}
            is represented by a marker on its place of interest,
            surrounded by a circle: this is the active zone.
            <strong>Get into it to start listening, or out of it to stop.</strong>
          </Typography>

          <Typography>
            Enjoy the new way of exploring the world around you with
            {' '}
            <strong>agami</strong>
            .
          </Typography>

          <Button
            size="sm"
            onClick={() => navigate('/experience/679ec009-b360-40c3-bf21-ed49e4a7789c')}
          >
            EXPLORE AGAMI GLOBAL
          </Button>
        </div>
      </div>

      <Divider color="dark" />

      <div className="grid grid-cols-2 gap-8 py-4 lg:py-10 justify-center mb-8">

        <div className="flex flex-col gap-4 col-span-2 lg:col-span-1 items-center lg:items-start">
          <Typography variant="h2">
            Simulate your location
          </Typography>

          <img
            src={step3}
            className="block lg:hidden w-full border rounded shadow col-span-2 lg:col-span-1"
            title="simulate position"
            alt="simulate position"
          />

          <Typography>
            To prepare your journey, you can listen to the
            {' '}
            agami
            {' '}
            of an
            experience by
            {' '}
            <strong>simulating your location</strong>
            .
            Select an experience, then
            {' '}
            <strong>hit on the footsteps icon</strong>
            {' '}
            to activate the simulation.
            Use the navigation tools to zoom on the active zone of an
            {' '}
            agami
            {' '}
            ,
            then click or tap into the active zone to start listening,
            or out of it to stop.
          </Typography>

          <Typography>
            Enjoy exploring the whole world without leaving your house!
          </Typography>

          <Button
            size="sm"
            onClick={() => navigate('/experience/679ec009-b360-40c3-bf21-ed49e4a7789c')}
          >
            EXPLORE AGAMI GLOBAL
          </Button>

        </div>

        <img
          src={step3}
          className="hidden lg:block  w-full border rounded shadow col-span-2 lg:col-span-1"
          title="simulate position"
          alt="simulate position"
        />
      </div>

    </Container>
  );
};

export default HowItWorks;

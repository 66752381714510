import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { PiEnvelopeLight, PiQrCodeLight } from 'react-icons/pi';
import {
  Typography,
  Button,
  Input,
  Select,
  Option,
  Dialog,
  DialogBody,
  DialogFooter,
  Spinner,
  Alert,
} from '@material-tailwind/react';
import ShortUniqueId from 'short-unique-id';
import RoleChip from '../components/RoleChip';
import { validateEmail } from '../utils/helpers/validate.helpers';
import { sendUpgradeCode } from '../utils/helpers/supabase.helpers';
import { getRoleId, getRoleLabel } from '../utils/helpers/roles.helpers';
import { useGeneralToast } from '../hooks/toast';
import Container from '../components/Container';
import GoBack from '../components/GoBack';

const { randomUUID } = new ShortUniqueId({ length: 10 });

const GenerateCode = () => {
  const { success: toastSuccess, error: toastError } = useGeneralToast();

  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [emailErr, setEmailErr] = useState(false);
  const [roleErr, setRoleErr] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successOp, setSuccessOp] = useState(false);
  const [generatedCode, setGeneratedCode] = useState('');

  const onEmailChange = ({ target }) => {
    setEmailErr(false);
    setEmail(target.value);
  };

  const onRoleChange = (value) => {
    setRoleErr(false);
    setRole(value);
  };

  const onSubmit = () => {
    if (!validateEmail(email)) {
      setEmailErr(true);
    }

    if (!role) {
      setRoleErr(true);
    }

    if (validateEmail(email) && role) {
      setShowConfirm(true);
    }
  };

  const onConfirm = async () => {
    setShowConfirm(false);

    setIsLoading(true);

    const code = randomUUID();

    const roleId = getRoleId(role);
    const roleLabel = getRoleLabel(role);

    const { error } = await sendUpgradeCode({
      email,
      role: roleId,
      roleLabel,
      code,
    });

    if (error) {
      console.error(error);
      toastError(error.message ?? JSON.stringify(error));
    } else {
      toastSuccess('Mail sent successfully!');
      setGeneratedCode(code);
      setSuccessOp(true);
    }

    setIsLoading(false);
  };

  return (
    <Container
      bgColorClass="bg-white"
      direction="col"
      className="text-primary"
    >
      <Dialog
        open={showConfirm}
        size="xs"
      >
        <DialogBody
          className="text-black"
        >
          Do you confirm to send an UPGRADE CODE to the address
          {' '}
          <strong>{email}</strong>
          {' '}
          for the role
          {' '}
          <span className="inline-block"><RoleChip role={role} /></span>
          ?
        </DialogBody>
        <DialogFooter className="gap-2">
          <Button
            size="sm"
            color="red"
            variant="outlined"
            onClick={() => setShowConfirm(false)}
          >
            CANCEL
          </Button>
          <Button
            size="sm"
            color="yellow"
            onClick={onConfirm}
          >
            CONFIRM
          </Button>
        </DialogFooter>
      </Dialog>

      <div className="mb-2 self-start">
        <GoBack />
      </div>

      <div className="flex flex-col max-w-lg mx-auto mb-12">
        <Typography variant="lead">Generate upgrade code</Typography>

        <div className="my-6">
          <Alert
            color="blue"
            variant="ghost"
            icon={<PiQrCodeLight className="h-8 w-8" />}
          >
            Use this form to send a code to
            {' '}
            <span className="font-bold">AN EXISTING USER</span>
            .
            {' '}
            <Link
              to="/admin/new-user"
              className="underline"
            >
              Click here
            </Link>
            {' '}
            to invite a new user.
          </Alert>
        </div>

        <div className="my-2">

          <Input
            type="email"
            label="Existing user email Address"
            value={email}
            onChange={onEmailChange}
            error={emailErr}
            readOnly={successOp}
            disabled={successOp}
          />

          <Typography
            style={{ visibility: emailErr ? 'visible' : 'hidden' }}
            variant="small"
            color="red"
          >
            Insert a valid email
          </Typography>

        </div>

        <div className="my-2">

          <Select
            label="Plan"
            error={roleErr}
            value={role}
            onChange={onRoleChange}
            disabled={successOp}
          >
            <Option value="early_bird">
              <RoleChip role="early_bird" />
            </Option>
            <Option value="content_creator">
              <RoleChip role="content_creator" />
            </Option>
            <Option value="venue">
              <RoleChip role="venue" />
            </Option>
          </Select>

          <Typography
            style={{ visibility: roleErr ? 'visible' : 'hidden' }}
            variant="small"
            color="red"
          >
            Choose a plan
          </Typography>
        </div>

        {!successOp && (
          <div className="my-2">
            <Button
              color="yellow"
              size="sm"
              onClick={onSubmit}
              icon={isLoading ? <Spinner className="h-4 w-4" /> : <PiEnvelopeLight className="text-lg" />}
              disabled={isLoading}
            >
              SEND INVITE
            </Button>
          </div>
        )}

        {successOp && (
          <div className="my-2">
            <Alert
              color="green"
              variant="ghost"
            >
              You successfully created an upgrade code.
              An email has been sent to the user&apos;s email address.
              You can monitor the operation from
              {' '}
              <Link
                to="/admin/list-codes"
                className="underline"
              >
                this section.
              </Link>
              {' '}
              Here are the details:
              <ul className="mt-2 ml-2 list-inside list-disc">
                <li>
                  User:
                  <span className="px-1 font-bold">{email}</span>
                </li>
                <li>
                  Role:
                  <span className="px-1 font-bold">{role}</span>
                </li>
                <li>
                  Code:
                  <span className="px-1 font-bold">{generatedCode}</span>
                </li>
              </ul>
            </Alert>
          </div>
        )}
      </div>

    </Container>
  );
};

export default GenerateCode;

import React, { useState } from 'react';
import {
  Input, Spinner, Typography, Button,
} from '@material-tailwind/react';
import { PiGiftLight } from 'react-icons/pi';
import { useSearchParams } from 'react-router-dom';
import { useSessionContext } from '../contexts/SessionContext';
import { redeemUpgradeCode, updateUser } from '../utils/helpers/supabase.helpers';
import RedeemCodeMessage from './RedeemCodeMessage';
import { useGeneralToast } from '../hooks/toast';

const RedeemCodePanel = () => {
  const { refresh, user } = useSessionContext();
  const { error: toastError, success: toastSuccess } = useGeneralToast();
  const [searchParams] = useSearchParams();
  const codeParam = searchParams.get('redeemCode');
  const [redeemCode, setRedeemCode] = useState(codeParam);
  const [redeemCodeError, setRedeemCodeError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleRedeemCode = async (code) => {
    if (!code) {
      return;
    }

    setLoading(true);

    const { data, error } = await redeemUpgradeCode({
      code,
      email: user.email,
    });

    if (error) {
      setRedeemCodeError(error);
      setLoading(false);
      return;
    }

    const redeemedRole = data.target_role;

    const { error: updateUserError } = await updateUser({
      attributes: { role_id: redeemedRole },
      id: user.id,
    });

    if (updateUserError) {
      toastError(updateUserError.message ?? 'Something went wrong');
      setLoading(false);
      return;
    }

    toastSuccess('Your account has been upgraded successfully!');

    setRedeemCode(null);

    refresh();
  };

  return (
    <div className="w-full mt-6">

      <Typography
        variant="h5"
        className="mb-2"
      >
        Redeem an upgrade code
      </Typography>

      <Input
        className="w-full font-bold"
        value={redeemCode || ''}
        onChange={({ currentTarget }) => {
          setRedeemCodeError(false);
          setRedeemCode(currentTarget.value);
        }}
        onKeyDown={({ code }) => {
          if (code === 'Enter') {
            handleRedeemCode(redeemCode);
          }
        }}
        type="text"
        label="Your upgrade code"
        success={!!redeemCode && !redeemCodeError}
        error={!!redeemCode && redeemCodeError}
      />
      {redeemCode && (
        <>
          <RedeemCodeMessage
            redeemCodeError={redeemCodeError}
            setRedeemCode={setRedeemCode}
            setRedeemCodeError={setRedeemCodeError}
          />
          <Button
            className="flex flex-grow-0 items-center gap-2 mt-2"
            size="sm"
            onClick={() => handleRedeemCode(redeemCode)}
            disabled={loading || redeemCodeError}
          >
            {loading ? <Spinner className="h-4 w-4" /> : <PiGiftLight className="text-lg" />}
            {' '}
            REDEEM
          </Button>
        </>
      )}
    </div>
  );
};

export default RedeemCodePanel;

import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton, Typography } from '@material-tailwind/react';
import {
  PiPlusBold,
  PiArrowLineDownDuotone,
  PiCheckBold,
  PiSpeakerSimpleHigh,
} from 'react-icons/pi';
import { useDropzone } from 'react-dropzone';
import ReactAudioPlayer from 'react-audio-player';
import { mimeTypeExtension } from '../utils/helpers/mime.heplers';
import { useGeneralToast } from '../hooks/toast';
import AudioFilePreview from '../components/AudioFilePreview';
import { AgamiSpotDetailPropType } from '../PropTypes';

const AudioFileDropzone = ({
  spot,
  uploadSizeLimit,
  allowedFileTypes,
  audioFile,
  setAudioFile,
  existingSpotEdit,
}) => {
  const { error: toastError } = useGeneralToast();
  const [uploadedAudioFileSrc, setUploadedAudioFileSrc] = useState(null);
  const uploadLimitInMB = (uploadSizeLimit / 1048576);
  const allowedFileExtension = allowedFileTypes?.map((mime) => mimeTypeExtension(mime));
  const accept = allowedFileTypes?.reduce(
    (acc, curr) => ({ ...acc, [curr]: [`.${mimeTypeExtension(curr)}`] }),
    {},
  );

  const onDrop = useCallback((acceptedFiles) => {
    if (!acceptedFiles || acceptedFiles.length === 0) {
      toastError('Wrong file type.');
    }
    setAudioFile(acceptedFiles[0]);
  }, []);

  useEffect(() => {
    let mounted = true;
    if (!audioFile) {
      setUploadedAudioFileSrc(undefined);
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      if (!mounted) return;
      if (!reader.result) {
        toastError('Issue loading audio file');
      }
      setUploadedAudioFileSrc(reader.result);
    };
    reader.readAsDataURL(audioFile);

    return () => {
      mounted = false;
    };
  }, [audioFile]);

  const {
    getRootProps, getInputProps, isDragActive, open,
  } = useDropzone({
    onDrop,
    accept,
    maxFiles: 1,
  });

  const dropZoneStyle = useMemo(() => {
    if (isDragActive) return 'bg-primary-50 border-primary';
    if (!isDragActive && !uploadedAudioFileSrc) return 'bg-base-gray border-base-gray-600 hover:bg-base-gray-600 cursor-pointer';
    if (!isDragActive && uploadedAudioFileSrc) return 'bg-primary-200 border-primary-400 hover:bg-primary-400 cursor-pointer';
  }, [isDragActive, uploadedAudioFileSrc]);

  const editMode = useMemo(
    () => existingSpotEdit && !uploadedAudioFileSrc,
    [existingSpotEdit, uploadedAudioFileSrc],
  );

  return (
    <>

      {editMode && (
        <>
          <AudioFilePreview spot={spot} />
          <Button
            color="primary"
            size="sm"
            onClick={open}
            className="flex justify-center items-center gap-4"
          >
            <PiSpeakerSimpleHigh className="h-4 w-4" />
            CHANGE AUDIO
          </Button>
        </>
      )}

      <div
        {...getRootProps()}
        className={`w-full rounded-lg p-10 border border-dashed ${dropZoneStyle}`}
        style={{
          display: editMode ? 'none' : 'block',
        }}
      >
        <input {...getInputProps()} />
        <div className="flex justify-center items-center gap-4">

          {isDragActive && (
            <>
              <PiArrowLineDownDuotone className="h-6 w-6" />
              <Typography className="text-primary font-bold">
                DROP IT LIKE IT&apos;S HOT
              </Typography>
            </>
          )}

          {(!isDragActive && !uploadedAudioFileSrc) && (
            <>
              <IconButton
                className="rounded-full bg-primary text-accent"
                size="sm"
                color="gray"
              >
                <PiPlusBold className="h-4 w-4" />
              </IconButton>
              <Typography className="text-primary font-bold">
                UPLOAD AN AUDIO FILE
              </Typography>
            </>
          )}

          {(!isDragActive && uploadedAudioFileSrc) && (
            <>
              <PiCheckBold className="text-white h-6 w-6" />
              <Typography className="text-white font-bold">
                {audioFile.name}
              </Typography>
            </>
          )}

        </div>
      </div>

      {!!uploadedAudioFileSrc && (
        <ReactAudioPlayer
          className="w-full mt-[-24px] bg-primary rounded-b-lg"
          src={uploadedAudioFileSrc}
          controls
        />
      )}

      <Typography
        variant="small"
        className="mt-[-8px]"
      >
        File size limit:
        {' '}
        <em className="font-bold">
          {uploadLimitInMB}
          {' '}
          MB
        </em>
        {' / '}
        Allowed file types:
        {' '}
        <em className="font-bold">
          {allowedFileExtension?.map((filetype) => (
            <React.Fragment key={filetype}>
              <span>{filetype}</span>
              {' '}
            </React.Fragment>
          ))}
        </em>
      </Typography>
    </>
  );
};

AudioFileDropzone.propTypes = {
  spot: AgamiSpotDetailPropType,
  existingSpotEdit: PropTypes.bool,
  uploadSizeLimit: PropTypes.number,
  allowedFileTypes: PropTypes.arrayOf(PropTypes.string),
  setAudioFile: PropTypes.func,
  audioFile: PropTypes.shape({
    lastModified: PropTypes.number,
    name: PropTypes.string,
    path: PropTypes.string,
    size: PropTypes.number,
    type: PropTypes.string,
    webkitRelativePath: PropTypes.string,
  }),
};

export default AudioFileDropzone;

import React, { useState } from 'react';
import {
  Button,
  Input,
  Option,
  Select,
  Spinner,
  Typography,
  Switch,
} from '@material-tailwind/react';
import { useFormData } from '../hooks/form';
import { validateInputNumber } from '../utils/helpers/validate.helpers';
import { useGeneralToast } from '../hooks/toast';
import { createExpAccessCode } from '../utils/helpers/supabase.helpers';

const CreateAccessCodeForm = ({ experienceList, refetch, closeDialog }) => {
  const toast = useGeneralToast();
  const [generating, setGenerating] = useState(false);

  const { formData, handleDataChange } = useFormData({
    anonymous_access: false,
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    setGenerating(true);

    if (!formData.experienceId) {
      toast.error('Choose an experience to generate an access code');
    }

    const { error } = await createExpAccessCode(formData);

    if (error) {
      console.error(error);
      toast.error(error.message ?? JSON.stringify(error));
      setGenerating(false);
      return;
    }

    refetch();
    setGenerating(false);
    if (typeof closeDialog === 'function') closeDialog();
    toast.success('Code succesfully generated');
  };

  return (
    <form
      className="flex flex-col gap-4 px-2"
      onSubmit={onSubmit}
    >

      <div className="flex flex-col">
        <Select
          label="Choose experience"
          value={formData.experienceId ?? ''}
          onChange={(val) => handleDataChange('experienceId', val)}
        >
          {experienceList.map((exp) => (
            <Option
              key={exp.id}
              value={exp.id}
            >
              {exp.name}
            </Option>
          ))}
        </Select>
        <Typography variant="small">
          The target experience
        </Typography>
      </div>

      <div className="flex flex-col">
        <Input
          value={formData.expiresAt ?? ''}
          onChange={(e) => handleDataChange('expiresAt', e.currentTarget.value)}
          type="datetime-local"
          label="Code expires at"
        />
        <Typography variant="small">
          After this date the the code will not
          work anymore (leave blank for always valid codes)
        </Typography>
      </div>

      <div className="flex flex-col">
        <Input
          value={formData.accessExpiresAfter ?? ''}
          onChange={(e) => handleDataChange(
            'accessExpiresAfter',
            validateInputNumber(e.currentTarget.value ?? ''),
          )}
          type="number"
          label="Expires after (days)"
        />
        <Typography variant="small">
          Access for the user expires after this
          amount of days (leave blank for lifetime access)
        </Typography>
      </div>

      <div className="flex flex-col">
        <Input
          value={formData.code ?? ''}
          onChange={(e) => handleDataChange('code', e.currentTarget.value)}
          type="text"
          label="Code"
        />
        <Typography variant="small">
          A code that the user will use to redeem the experience
          (leave blank to let us generate
          a random code for you, e.g. BWX7SY)
        </Typography>
      </div>

      <div className="flex flex-col">
        <Switch
          id="anonymous_access"
          label="Anonymous access code"
          checked={Boolean(formData.anonymous_access)}
          onChange={(e) => handleDataChange('anonymous_access', e.currentTarget.checked)}
        />
        <Typography variant="small">
          If this option is enabled, the code will let anonymous
          users (without an account) to access the experience.
        </Typography>
      </div>

      <Button
        type="submit"
        disabled={generating || !formData.experienceId}
      >
        {generating ? <Spinner className="mx-auto" /> : 'GENERATE CODE'}
      </Button>

    </form>
  );
};

export default CreateAccessCodeForm;

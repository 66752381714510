import React from 'react';
import { Button, Carousel } from '@material-tailwind/react';
import { PiCaretLeftBold, PiCaretRightBold } from 'react-icons/pi';
import Player from '../components/Player';

const prevArrow = ({ handlePrev }) => (
  <Button
    onClick={handlePrev}
    color="primary"
    size="sm"
    variant="text"
    className="!absolute top-2/4 left-0 -translate-y-2/4"
  >
    <PiCaretLeftBold className="h-6 w-6" />
  </Button>
);

const nextArrow = ({ handleNext }) => (
  <Button
    onClick={handleNext}
    color="primary"
    size="sm"
    variant="text"
    className="!absolute top-2/4 !right-0 -translate-y-2/4"
  >
    <PiCaretRightBold className="h-6 w-6" />
  </Button>
);

const navigation = ({ setActiveIndex, activeIndex, length }) => (
  <div className="absolute bottom-0 left-2/4 z-50 flex -translate-x-2/4 gap-2">
    {new Array(length).fill('').map((_, idx) => {
      const navColor = activeIndex === idx ? 'w-8 bg-primary' : 'w-4 bg-primary/50';
      return (
        <span
          // eslint-disable-next-line
          key={idx}
          className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${navColor}`}
          onClick={() => setActiveIndex(idx)}
        />
      );
    })}
  </div>
);

const PlayersCarousel = ({ activeSpots }) => (
  <div
    className="absolute bottom-0 w-full md:w-[36rem] md:bottom-6 md:left-1/2 md:-translate-x-1/2 px-0 md:px-6 py-4 z-[999] bg-white shadow rounded"
  >
    <Carousel
      className="rounded"
      prevArrow={prevArrow}
      nextArrow={nextArrow}
      navigation={navigation}
    >
      {activeSpots?.map((activeSpot, idx) => (
        <div
          key={activeSpot.id}
          className="relative -top-1 text-primary px-6 py-4 w-3/4 mx-auto"
        >
          <Player
            idx={idx}
            spot={activeSpot}
          />
        </div>
      ))}
    </Carousel>
  </div>
);

export default PlayersCarousel;

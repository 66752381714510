import React from 'react';
import { Link } from 'react-router-dom';
import { useFeatureAuth } from '../hooks/authorization';
import { FEATURES } from '../utils/constants/features.constants';

const RequireAdmin = ({ children }) => {
  const isAuthorised = useFeatureAuth(FEATURES.ADMIN_PANEL);

  if (!isAuthorised) {
    return (
      <div className="pt-10 text-center">
        <h2 className="text-2xl font-bold">Ops! You can&apos;t be here!</h2>
        <Link to="/">
          <span
            className="inline-block mt-10 mx-auto px-6 py-3 rounded bg-while text-primary hover:bg-gray-200 border border-primary"
          >
            GO BACK HOME
          </span>
        </Link>
      </div>
    );
  }

  return children;
};

export default RequireAdmin;

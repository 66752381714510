import React, { useRef, useMemo, useState } from 'react';

import * as L from 'leaflet';

import {
  Circle, MapContainer, Marker, TileLayer, useMap,
} from 'react-leaflet';

import { DEFAULT_MAP_ZOOM, DEFAULT_SPOT_DEADBAND, DEFAULT_SPOT_RADIUS } from '../utils/constants/geoxp.constants';

import { DEFAULT_ICON, RADIUS_CIRCLE_STYLE_OPTS, DEADBAND_CIRCLE_STYLE_OPTS } from '../utils/constants/map.constants';

import { MAPBOX_MONOCHROME_TILESET_URL } from '../utils/constants/mapbox.constants';

const SpotPositionPreview = ({
  latitude,
  setLatitude,
  longitude,
  setLongitude,
  radius,
  deadband,
  interactive,
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const center = useMemo(
    () => (latitude && longitude ? L.latLng(latitude, longitude) : null),
    [latitude, longitude],
  );

  const ChangeView = ({ viewCenter }) => {
    const map = useMap();
    // keep current user zoom
    const currentZoom = map.getZoom();
    map.setView(viewCenter, currentZoom);
    return null;
  };

  const markerRef = useRef(null);

  const markerEventHandlers = useMemo(() => ({

    dragstart: () => setIsDragging(true),

    dragend: () => {
      if (!markerRef.current) {
        return;
      }

      const markerPos = markerRef.current.getLatLng();

      const { lat, lng } = markerPos;

      setLatitude(lat);

      setLongitude(lng);

      // show circle
      setIsDragging(false);
    },

  }), []);

  return center && (
    <div className="w-full h-[400px]">
      <MapContainer
        center={center}
        zoom={19}
        scrollWheelZoom={false}
        className="h-full w-full z-0"
      >

        <TileLayer url={MAPBOX_MONOCHROME_TILESET_URL} />

        <ChangeView viewCenter={center} zoom={DEFAULT_MAP_ZOOM} />

        <Marker
          ref={markerRef}
          draggable={interactive}
          eventHandlers={interactive ? markerEventHandlers : {}}
          position={center}
          icon={DEFAULT_ICON}
        />

        {!isDragging && (
          <Circle
            center={center}
            radius={radius || DEFAULT_SPOT_RADIUS}
            pathOptions={RADIUS_CIRCLE_STYLE_OPTS}
          />
        )}

        {!isDragging && deadband && (
          <Circle
            center={center}
            radius={
              (radius || DEFAULT_SPOT_RADIUS) + (deadband || DEFAULT_SPOT_DEADBAND)
            }
            pathOptions={DEADBAND_CIRCLE_STYLE_OPTS}
          />
        )}

      </MapContainer>

      {interactive && (
        <small className="inline-block">
          <em>Drag and drop marker to change position</em>
        </small>
      )}
    </div>
  );
};

export default SpotPositionPreview;

import { MAPBOX_TOKEN } from '../constants/mapbox.constants';

export const getGeocoding = async (search) => {
  try {
    const res = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${search}.json?proximity=ip&access_token=${MAPBOX_TOKEN}`);

    if (res.status !== 200) {
      return {
        error: `geocoding api error: ${res.status} - ${res.statusText}`,
      };
    }

    const data = await res.json();

    return { data };
  } catch (error) {
    return { error };
  }
};

export const getReverseGeocoding = async ({ latitude, longitude }) => {
  try {
    const res = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?limit=1&access_token=${MAPBOX_TOKEN}`);
    if (res.status !== 200) {
      return {
        error: `geocoding api error: ${res.status} - ${res.statusText}`,
      };
    }
    const data = await res.json();
    return { data };
  } catch (error) {
    return { error };
  }
};

export const getLocation = () => new Promise((resolve, reject) => {
  navigator.geolocation.getCurrentPosition(
    (position) => {
      resolve({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    },
    (error) => {
      reject(error);
    },
  );
});

export const getLocationAndReverseGeocoding = async () => {
  try {
    const location = await getLocation();
    const res = {
      latitude: location.latitude,
      longitude: location.longitude,
      radius: 40,
      label: 'My location',
    };

    try {
      const reverseGeocoding = await getReverseGeocoding({
        latitude: location.latitude,
        longitude: location.longitude,
      });
      res.label = reverseGeocoding.data.features[0].text;
    } catch {
      // do nothing
    }

    return res;
  } catch (error) {
    return { error };
  }
};

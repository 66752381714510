import { useSessionContext } from '../contexts/SessionContext';

import { PLANS, UPLOAD_SPOT_LIMIT } from '../utils/constants/plans.constants';

export const useFeatureAuth = (feature) => {
  const { user } = useSessionContext();

  if (!user) {
    return false;
  }

  const role = user?.role;

  const roleFeatures = PLANS[role];

  return roleFeatures?.includes(feature);
};

export const useUploadSpotLimit = () => {
  const { user } = useSessionContext();

  if (!user) {
    return false;
  }

  const role = user?.role;

  const uploadLimit = UPLOAD_SPOT_LIMIT[role];

  return uploadLimit;
};

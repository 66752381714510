import React from 'react';
import { Spinner, Typography } from '@material-tailwind/react';
import { Link } from 'react-router-dom';
import { useSessionContext } from '../contexts/SessionContext';
import AgamiPreviewCard from './AgamiPreviewCard';
import NoUploads from '../components/NoUploads';
import { useUserAgamiList } from '../hooks/agami';

const AgamiCardGrid = () => {
  const { user } = useSessionContext();
  const { isLoading, agamiList, refetch } = useUserAgamiList({ getAudioUrls: true });
  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">

      {isLoading && (
        <Spinner className="h-6 w-6 mt-4" />
      )}

      {!isLoading && (!agamiList || agamiList?.length === 0) && (
        <div className="w-full col-span-3 text-center">
          <NoUploads>
            <Typography>
              Looks like you haven&apos;t uploaded anything yet.
              {' '}
              <Link to="/upload" className="underline">Start here</Link>
              {' '}
              or
              {' '}
              <Link to="/faq#spot-faq" className="underline">read more</Link>
              .
            </Typography>
          </NoUploads>
        </div>
      )}

      {!isLoading && agamiList?.map((spot) => (
        <div key={spot.id} className="flex-1 mb-4 basis-1/4">
          <AgamiPreviewCard
            userId={user.id}
            spot={spot}
            refetchAgamiList={refetch}
          />
        </div>
      ))}
    </div>
  );
};

export default AgamiCardGrid;

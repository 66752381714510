import React, { useRef, useEffect, useCallback } from 'react';
import Mapbox, { GeolocateControl, NavigationControl, ScaleControl } from 'react-map-gl';
import { useXp } from '../contexts/XpContext';
import XpSpotsMarkers from './XpSpotsMarkers';
import { MAPBOX_LIGHT_MAP_STYLE, MAPBOX_TOKEN } from '../utils/constants/mapbox.constants';
import HeaderSpace from '../layout/HeaderSpace';
import Controls from './Controls';
import ManualModeInstructions from './ManualModeInstructions';
import { useMapControls } from '../contexts/MapControlsContext';
import ManualLocationMarker from './ManualLocationMarker';
import { useDevice } from '../hooks/device';

const MapboxMap = () => {
  const { positions, manualMode, updateLocation } = useXp();
  const { mapRef, setRouteSegment } = useMapControls();
  const { platformType } = useDevice();
  const isMobile = platformType === 'mobile';

  const geoControlRef = useRef(null);

  useEffect(() => {
    // Activate as soon as the control is loaded
    if (geoControlRef.current) {
      setTimeout(
        () => geoControlRef.current?.trigger(),
        1000,
      );
    }
  }, [geoControlRef.current]);

  const handleMapClick = useCallback((event) => {
    if (manualMode) {
      const { lng, lat } = event.lngLat;
      updateLocation({ latitude: lat, longitude: lng });
    }
  }, [manualMode, updateLocation]);

  return (
    <div className="h-[calc(100%_-_4.5rem)] w-full relative">

      <HeaderSpace className="headerspace" />

      <Mapbox
        ref={mapRef}
        mapboxAccessToken={MAPBOX_TOKEN}
        mapStyle={MAPBOX_LIGHT_MAP_STYLE}
        attributionControl={false}
        style={{
          height: '100%',
          width: '100%',
        }}
        initialViewState={{
          longitude: 7.675806773171514,
          latitude: 45.06451515609597,
          zoom: 4,
        }}
        onClick={handleMapClick}
      >

        {manualMode && (
          <>
            <ManualModeInstructions />
            <ManualLocationMarker />
          </>
        )}

        <ScaleControl />

        <Controls />

        <NavigationControl />

        {!manualMode && (
          <GeolocateControl
            ref={geoControlRef}
            trackUserLocation={isMobile}
            showUserHeading={isMobile}
            positionOptions={{
              enableHighAccuracy: true,
            }}
            onGeolocate={(location) => {
              setRouteSegment({
                longitude: location.coords.longitude,
                latitude: location.coords.latitude,
              });
            }}
          />
        )}

        {positions?.length > 0 && <XpSpotsMarkers />}

      </Mapbox>
    </div>
  );
};

export default MapboxMap;

import { useState } from 'react';

export const useFormData = (initialFormData) => {
  const [formData, setFormData] = useState(initialFormData);

  const handleDataChange = (key, newValue) => {
    // if a function is passed, invoke it
    // with the previous val as ann argument
    if (typeof newValue === 'function') {
      setFormData((currentData) => {
        const currKeyVal = currentData[key];
        const newData = newValue(currKeyVal);
        return {
          ...currentData,
          [key]: newData,
        };
      });
      return;
    }

    // otherwise just set the new value
    setFormData((currentData) => ({
      ...currentData,
      [key]: newValue,
    }));
  };

  const checkInputIsValid = (key, condition, fallbackVal) => {
    if (!condition) {
      setFormData((currentData) => ({
        ...currentData,
        [key]: fallbackVal ?? '',
      }));
    }
  };

  return {
    formData,
    setFormData,
    handleDataChange,
    checkInputIsValid,
  };
};

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import ReactAudioPlayer from 'react-audio-player';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  IconButton,
  Spinner,
  Typography,
} from '@material-tailwind/react';
import { PiTrash, PiWarningCircle, PiSpeakerX } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import { deleteGeoXpSpot } from '../utils/helpers/user-spot.helpers';
import SpotPositionBanner from '../components/SpotPositionBanner';
import { useWindowSize } from '../hooks/toolkit';
import { AgamiSpotDetailPropType } from '../PropTypes';
import { useGeneralToast } from '../hooks/toast';
import AgamiTypeChip from '../components/AgamiTypeChip';

const AgamiPreviewCard = ({
  userId,
  spot,
  removeFromExperience,
  refetchAgamiList,
}) => {
  const { success: toastSuccess, error: toastError } = useGeneralToast();
  const [deleting, setDeleting] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const { width } = useWindowSize();
  const dialogSize = width <= 640 ? 'lg' : 'xs';

  const navigate = useNavigate();

  // delete globally
  const deleteSpot = useCallback(async () => {
    setDeleting(true);

    const { error } = await deleteGeoXpSpot({
      userId,
      spotId: spot.id,
      audioPath: spot.audio_content_path,
    });

    if (error) {
      console.error(error);
      toastError('Something went wrong');
      setDeleting(false);
      return;
    }

    await refetchAgamiList();
    toastSuccess('Agami succesfully deleted');
    setDeleting(false);
    setOpenConfirm(false);
  }, [userId, spot, refetchAgamiList]);

  const manageDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (
      removeFromExperience
      && typeof removeFromExperience === 'function'
    ) {
      // remove from experience
      removeFromExperience();
    } else {
      setOpenConfirm(true);
    }
  };

  const editSpot = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/spot/${spot.id}`);
  };

  return (
    <>
      <Dialog
        size={dialogSize}
        open={openConfirm}
        handler={setOpenConfirm}
      >
        <DialogHeader>
          Delete agami
        </DialogHeader>
        <DialogBody>
          <Alert
            variant="ghost"
            color="amber"
            icon={<PiWarningCircle className="h-6 w-6" />}
          >
            This operation cannot be undone!
          </Alert>

          <span className="block my-2">
            Are you sure you want to delete
            your agami
            {' '}
            <strong>{spot.name}</strong>
            {' '}
            from
            your library?
          </span>
        </DialogBody>
        <DialogFooter className="justify-between gap-2">
          <Button
            size="sm"
            color="primary"
            variant="text"
            onClick={() => setOpenConfirm(false)}
            disabled={deleting}
          >
            CANCEL
          </Button>
          <Button
            size="sm"
            color="red"
            className="bg-accent-red"
            onClick={deleteSpot}
            disabled={deleting}
          >
            {deleting ? <Spinner className="h-4 w-4" /> : 'CONFIRM DELETE'}
          </Button>
        </DialogFooter>
      </Dialog>
      <Card
        className="w-full h-full cursor-pointer hover:bg-base-gray-400"
        onClick={editSpot}
      >
        <CardBody>
          <div className="flex space-x-2 justify-between items-start">
            <div className="flex space-x-4 items-center">
              {spot.type === 1 && (
                <div className="w-[90px] h-[90px] rounded-full overflow-hidden">
                  <SpotPositionBanner
                    latitude={spot.latitude}
                    longitude={spot.longitude}
                    radius={spot.radius}
                    interactive={false}
                  />
                </div>
              )}
              <div className="flex-1">
                <Typography className="font-bold">
                  {spot.name}
                </Typography>
                <Typography
                  variant="small"
                  className="inline-block mr-1"
                >
                  Type
                </Typography>
                <AgamiTypeChip
                  agamiType={spot?.type}
                />
                {spot?.type === 2
                  && spot?.experience_content_spots?.[0].manual_code
                  && (
                    <Typography variant="small">
                      Playback code
                      {' '}
                      <strong className="text-black">
                        {spot?.experience_content_spots?.[0].manual_code}
                      </strong>
                    </Typography>
                  )}
                {/* <Typography variant="small">
                  Status
                  {' '}
                  <strong>{spot.audio_status}</strong>
                </Typography> */}
                <Typography variant="small">
                  Created
                  {' '}
                  <strong className="text-black">
                    {new Date(spot.inserted_at).toLocaleDateString('en-GB')}
                  </strong>
                </Typography>
              </div>
            </div>
            <IconButton
              variant="text"
              size="sm"
              color="yellow"
              onClick={manageDelete}
              disabled={deleting}
            >
              <PiTrash className="text-primary text-[20px]" />
            </IconButton>
          </div>
          {spot.audioUrl
            ? (
              <ReactAudioPlayer
                className="w-[130%] ml-[-15%] h-6 mt-2 scale-75"
                src={spot.audioUrl}
                controls
              />
            )
            : (
              <Alert
                size="sm"
                color="amber"
                variant="ghost"
                className="w-full md:w-1/2 py-2 px-4 mt-2 text-xs"
                icon={<PiSpeakerX className="w-4 h-4" />}
              >
                Missing audio file
              </Alert>
            )}
        </CardBody>
      </Card>
    </>
  );
};

AgamiPreviewCard.propTypes = {
  userId: PropTypes.string,
  spot: AgamiSpotDetailPropType,
  removeFromExperience: PropTypes.func,
  refetchAgamiList: PropTypes.func,
};

export default AgamiPreviewCard;

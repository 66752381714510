import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import ScrollToTop from './ScrollToTop';

const Layout = () => (
  <>
    <ScrollToTop />
    <div className="h-full min-h-screen">
      {/* A "layout route" is a good place to put markup you want to
        share across all the pages on your site, like navigation. */}
      <Header />

      {/* An <Outlet> renders whatever child route is currently active,
        so you can think about this <Outlet> as a placeholder for
        the child routes we defined above. */}
      <Outlet />

      {!process.env.REACT_APP_COMING_SOON_PREVIEW && <Footer />}
    </div>
  </>
);

export default Layout;

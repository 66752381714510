import React, { useRef, useState } from 'react';
import { IconButton } from '@material-tailwind/react';
import { PiPlayCircleLight, PiStopCircleFill } from 'react-icons/pi';

const PlayerMini = ({ audioUrl }) => {
  const audioRef = useRef(null);
  const [canPlay, setCanPlay] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const startAudio = (audioEl) => {
    if (!audioEl) return;
    // eslint-disable-next-line
    audioEl.currentTime = 0;
    audioEl.play();
    setIsPlaying(true);
  };

  const stopAudio = (audioEl) => {
    if (!audioEl) return;
    audioEl.pause();
    // eslint-disable-next-line
    audioEl.currentTime = 0;
    setIsPlaying(false);
  };

  const togglePlay = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!canPlay || !audioRef.current) return;
    if (audioRef.current.paused) {
      startAudio(audioRef.current);
    } else {
      stopAudio(audioRef.current);
    }
  };

  return (
    <div className="w-full flex justify-center items-center">
      <IconButton
        variant="text"
        className="text-primary"
        onClick={togglePlay}
      >
        {isPlaying
          ? <PiStopCircleFill className="h-6 w-6" />
          : <PiPlayCircleLight className="h-6 w-6" />}
      </IconButton>

      {/* eslint-disable-next-line jsx-a11y/media-has-caption  */}
      <audio
        ref={audioRef}
        className="hidden"
        src={audioUrl}
        onCanPlay={() => setCanPlay(true)}
      />
    </div>
  );
};

export default PlayerMini;

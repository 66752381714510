import React, { useState } from 'react';
import {
  Typography, Input, Button, Spinner, Alert,
} from '@material-tailwind/react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { signInWithEmail } from '../utils/helpers/supabase.helpers';
import { useSessionContext } from '../contexts/SessionContext';
import { useGeneralToast } from '../hooks/toast';
import { usePageTitle } from '../hooks/toolkit';
import Container from '../components/Container';

const CreateAccount = () => {
  usePageTitle('Register | Agami');

  const { success: toastSuccess, error: toastError } = useGeneralToast();

  const { user } = useSessionContext();

  const location = useLocation();

  const [email, setEmail] = useState('');

  const [loading, setLoading] = useState(false);

  const [success, setSuccess] = useState(false);

  if (user) {
    // send a logged user to its dashboard
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }

  const register = async (e) => {
    setLoading(true);

    e.stopPropagation();
    e.preventDefault();

    if (!email) {
      return;
    }

    const { error } = await signInWithEmail({ email });

    if (error) {
      console.error(error);
      toastError('Something went wrong');
      setLoading(false);
      setSuccess(false);
      return;
    }

    toastSuccess('Account created successfully!');
    setLoading(false);
    setSuccess(true);
  };

  return (
    <Container direction="col">

      <Typography
        className="mt-4 mb-12"
        variant="h1"
        color="white"
      >
        CREATE AN ACCOUNT
      </Typography>

      {!success && (
        <form
          className="w-full md:w-1/2 flex flex-col gap-8"
          onSubmit={register}
        >
          <Input
            color="white"
            value={email || ''}
            onChange={(e) => setEmail(e.currentTarget.value)}
            type="email"
            label="Email"
            required
            disabled={success}
          />
          <Button
            type="submit"
            disabled={loading}
          >
            {loading ? <Spinner className="h-4 w-4 mx-auto" /> : 'REGISTER'}
          </Button>
        </form>
      )}

      {success && (
        <div className="w-full md:w-1/2 flex flex-col mt-8">
          <Alert color="green">
            We&apos;re good! :) We sent you an email with all you need to start your
            magic journey with Agami :)
            Check your inbox at
            {' '}
            <strong>{email}</strong>
          </Alert>
        </div>
      )}

      <hr className="w-full md:w-1/2 my-6 opacity-50" />

      <div className="flex w-full justify-center items-center gap-2 my-2">
        <Typography
          color="white"
        >
          Don&apos;t have Bone Conduction Headphones yet?
        </Typography>
        <Link
          to="https://www.indiegogo.com/projects/agami-everywhere-immersive-sound"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography
            color="white"
            className="underline font-bold"
          >
            Buy now
          </Typography>
        </Link>
      </div>

      <div className="flex w-full justify-center items-center gap-2 my-2">
        <Typography
          color="white"
        >
          Already have an account?
        </Typography>
        <Link to="/signin">
          <Typography
            color="white"
            className="underline font-bold"
          >
            Sign In
          </Typography>
        </Link>
      </div>

    </Container>
  );
};

export default CreateAccount;

import { Typography } from '@material-tailwind/react';
import { Link } from 'react-router-dom';
import pkgInfo from '../../package.json';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="w-full px-4 md:px-16 z-50 text-white-yellow">
      <div className="border-t border-white-yellow flex flex-col md:flex-row justify-between items-center py-10">
        <div className="md:space-x-12 flex flex-col md:flex-row pb-10 md:pb-0 w-full">
          <Link className="opacity-90 hover:opacity-100 pb-2 md:pb-0" to="/about">
            About
          </Link>
          <Link className="opacity-90 hover:opacity-100 pb-2 md:pb-0" to="/how-it-works">
            How it works
          </Link>
          <Link className="opacity-90 hover:opacity-100 pb-2 md:pb-0" to="/faq">
            FAQ
          </Link>
          <Link className="opacity-90 hover:opacity-100 pb-2 md:pb-0" to="/support">
            Support
          </Link>
          <Link className="opacity-90 hover:opacity-100" to="/privacy-policy">
            Privacy policy
          </Link>
        </div>
        <Typography variant="small" className="whitespace-nowrap">
          ©
          {' '}
          {currentYear}
          {' '}
          MezzoForte SAS - Agami v
          {pkgInfo.version}
        </Typography>
      </div>
    </div>
  );
};

export default Footer;

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Alert,
  Button,
  Input,
  Spinner,
  Switch,
  Tooltip,
  Typography,
} from '@material-tailwind/react';
import { PiInfoLight } from 'react-icons/pi';
import Container from '../components/Container';
import { usePageTitle } from '../hooks/toolkit';
import { useSessionContext } from '../contexts/SessionContext';
import { createExperience } from '../utils/helpers/upload.helpers';
import { useGeneralToast } from '../hooks/toast';
import GoBack from '../components/GoBack';
import UploadExpAdvancedSettings from './UploadExpAdvancedSettings';
import { useFormData } from '../hooks/form';

const UploadExperience = () => {
  usePageTitle('Create Experience | Agami');

  const { user } = useSessionContext();
  const { success: toastSuccess, error: toastError } = useGeneralToast();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const { formData, handleDataChange, checkInputIsValid } = useFormData({
    spots_replay: true,
    spots_overlap: true,
    enable_high_accuracy: true,
  });

  const navigate = useNavigate();

  const handleCreateExperience = async (e) => {
    setLoading(true);

    e.preventDefault();
    e.stopPropagation();

    const {
      name,
      spots_replay,
      spots_overlap,
      enable_high_accuracy,
      default_radius,
      default_deadband,
      fade_in_time,
      fade_out_time,
      accuracy,
    } = formData;

    const insertData = {
      name,
      spots_replay,
      spots_overlap,
      enable_high_accuracy,
      ...(accuracy && { accuracy }),
      ...(default_radius && { default_radius }),
      ...(default_deadband && { default_deadband }),
      ...(fade_in_time && { fade_in_time }),
      ...(fade_out_time && { fade_out_time }),
      owner_id: user.id,
    };

    setLoading(false);

    const { data, error } = await createExperience({ insertData });

    if (error) {
      console.error(error);
      toastError('Something went wrong');
      setLoading(false);
      return;
    }

    console.info(data);
    toastSuccess('Experience created succesfully');
    setLoading(false);
    setSuccess(true);
    navigate(`/experiences/${data.id}`);
  };

  return (
    <Container
      bgColorClass="bg-white"
      direction="col"
    >
      <div className="mb-2 self-start">
        <GoBack />
      </div>

      <Typography
        variant="lead"
        className="text-primary"
      >
        CREATE A NEW EXPERIENCE
      </Typography>

      {success && (
        <div className="mt-8 text-center">
          <Alert
            color="green"
            variant="ghost"
          >
            <Typography>
              Experience created! Go to your
              {' '}
              <span className="font-bold underline">
                <Link to="/experiences">experiences dashboard</Link>
              </span>
              {' '}
              to add
              {' '}
              <em>agami</em>
              {' '}
              and share with other users.
            </Typography>
          </Alert>
        </div>

      )}

      {!success && (
        <form
          className="w-full md:w-1/2 lg:w-2/3 flex flex-col gap-4 my-10"
          onSubmit={handleCreateExperience}
        >
          <Input
            type="text"
            label="Experience name"
            value={formData.name ?? ''}
            onChange={(e) => handleDataChange('name', e.currentTarget.value)}
            required
          />

          <div className="flex justify-start items-center gap-1">
            <Switch
              id="spots_replay_switch"
              label="Spots replay"
              checked={Boolean(formData.spots_replay)}
              onChange={(e) => handleDataChange('spots_replay', e.currentTarget.checked)}
            />
            <Tooltip
              content={(
                <div className="w-[300px]">
                  <Typography
                    variant="small"
                    className="font-bold"
                  >
                    If false, when the user reenters a visited agami,
                    the application will NOT play its content again.
                  </Typography>
                  <Typography variant="small">
                    <strong>
                      <em>
                        default: true
                      </em>
                    </strong>
                  </Typography>
                </div>
              )}
              placement="right"
              interactive
            >
              <span>
                <PiInfoLight className="text-primary" />
              </span>
            </Tooltip>
          </div>

          <div className="flex justify-start items-center gap-1">
            <Switch
              id="spots_overlap_switch"
              label="Spots overlap"
              checked={Boolean(formData.spots_overlap)}
              onChange={(e) => handleDataChange('spots_overlap', e.currentTarget.checked)}
            />
            <Tooltip
              content={(
                <div className="w-[300px]">
                  <Typography
                    variant="small"
                    className="font-bold"
                  >
                    If false, when the user is actually inside multiple
                    agami at the same time (locations are overlapping,
                    or multiple agami are linked to the same location),
                    the application will play one content at a time, with no overlapping.
                    If true, all contents will be played at the same time.
                  </Typography>
                  <Typography variant="small">
                    <strong>
                      <em>
                        default: true
                      </em>
                    </strong>
                  </Typography>
                </div>
              )}
              placement="right"
              interactive
            >
              <span>
                <PiInfoLight className="text-primary" />
              </span>
            </Tooltip>
          </div>

          {/* advanced settings */}
          <UploadExpAdvancedSettings
            formData={formData}
            handleDataChange={handleDataChange}
            checkInputIsValid={checkInputIsValid}
          />

          <Button
            type="submit"
            disabled={loading}
          >
            {loading ? <Spinner className="h-4 w-4 mx-auto" /> : 'CREATE EXPERIENCE'}
          </Button>
        </form>
      )}
    </Container>
  );
};

export default UploadExperience;

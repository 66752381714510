import React, { useCallback } from 'react';
import { Layer, Marker, Source } from 'react-map-gl';
import { PiFootprintsFill } from 'react-icons/pi';
import { useXp } from '../contexts/XpContext';
import { POSITION_MARKER_COLOR } from '../utils/constants/map.constants';
import { useDevice } from '../hooks/device';
import { useMapControls } from '../contexts/MapControlsContext';

const ManualLocationMarker = () => {
  const { manualMode, location, updateLocation } = useXp();
  const { dragging, setDragging } = useMapControls();
  const { platformType } = useDevice();
  const center = [location?.coords?.longitude, location?.coords?.latitude];

  const feature = {
    type: 'Feature',
    id: 'manual_position_feature',
    geometry: {
      type: 'Point',
      coordinates: center,
    },
  };

  const circleStyle = {
    id: 'manual_position_circle',
    type: 'circle',
    paint: {
      'circle-radius': 14,
      'circle-stroke-color': POSITION_MARKER_COLOR.stroke,
      'circle-stroke-opacity': 0.5,
      'circle-stroke-width': 1,
      'circle-stroke-color-transition': {
        duration: 1000,
        delay: 0,
      },
      'circle-color': POSITION_MARKER_COLOR.fill,
      'circle-opacity': 0.5,
      'circle-color-transition': {
        duration: 1000,
        delay: 0,
      },
    },
  };

  const handleDragEnd = useCallback((event) => {
    if (manualMode) {
      const { lng, lat } = event.lngLat;
      updateLocation({ latitude: lat, longitude: lng });
    }
    setDragging(false);
  }, [manualMode, updateLocation]);

  return (
    // eslint-disable-next-line
    <>
      {location?.coords && (
        <>
          {!dragging && (
            <Source
              id="manual_position_source"
              type="geojson"
              data={feature}
            >
              <Layer {...circleStyle} />
            </Source>
          )}
          <Marker
            longitude={location.coords.longitude}
            latitude={location.coords.latitude}
            anchor="center"
            draggable={platformType !== 'mobile'}
            onDragStart={() => setDragging(true)}
            onDragEnd={handleDragEnd}
          >
            <PiFootprintsFill
              className="w-5 h-5"
              style={{
                color: POSITION_MARKER_COLOR.icon,
              }}
            />
          </Marker>
        </>
      )}
    </>
  );
};

export default ManualLocationMarker;

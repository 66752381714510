import { useEffect, useState, useCallback } from 'react';
import { useSessionContext } from '../contexts/SessionContext';
import {
  getUserOwnedExperience,
  getUserOwnedExperiences,
  getUserOwnedExperiencesWithoutDefault,
  getUserExpAccessCodes,
} from '../utils/helpers/supabase.helpers';
import {
  getUserAgamiListWithExpRelations,
  getUserAgamiList,
  getSpotsByExperienceId,
} from '../utils/helpers/user-spot.helpers';

export const useUserAgamiList = (options = {}) => {
  const { user } = useSessionContext();
  const [isLoading, setIsLoading] = useState(true);
  const [agamiList, setAgamiList] = useState(null);
  const { enabled, getExpRelations, getAudioUrls } = options;

  const fetchSpotsData = useCallback(
    async () => {
      if (enabled === false) {
        return;
      }

      const { spots, error } = getExpRelations
        ? await getUserAgamiListWithExpRelations({ userId: user.id, getAudioUrls })
        : await getUserAgamiList({ userId: user.id, getAudioUrls });

      if (error) {
        console.error(error);
        setIsLoading(false);
        return;
      }

      setAgamiList(spots);
      setIsLoading(false);
    },
    [
      user,
      enabled,
      getExpRelations,
      getAudioUrls,
      getUserAgamiListWithExpRelations,
      getUserAgamiList,
    ],
  );

  useEffect(() => {
    if (!user || enabled === false) {
      return;
    }

    fetchSpotsData();
  }, [user, enabled]);

  return { agamiList, isLoading, refetch: fetchSpotsData };
};

export const useUserAgamiListByExperience = ({ experienceId }) => {
  const { user } = useSessionContext();
  const [isLoading, setIsLoading] = useState(true);
  const [agamiList, setAgamiList] = useState(null);

  const fetchSpotsDataByExpId = useCallback(
    async () => {
      const { spots, error } = await getSpotsByExperienceId({
        userId: user.id,
        experienceId,
      });

      if (error) {
        console.error(error);
        setIsLoading(false);
        return;
      }

      setAgamiList(spots);
      setIsLoading(false);
    },
    [
      user,
      getSpotsByExperienceId,
      experienceId,
    ],
  );

  useEffect(() => {
    if (!user || !experienceId) {
      return;
    }
    fetchSpotsDataByExpId();
  }, [user, experienceId]);

  return { agamiList, isLoading, refetch: fetchSpotsDataByExpId };
};

export const useUserExperienceDetails = ({ experienceId }) => {
  const { user } = useSessionContext();
  const [isLoading, setIsLoading] = useState(true);
  const [experienceDetails, setExperienceDetails] = useState(null);

  const fetchExperienceDetails = useCallback(
    async () => {
      const { data, error } = await getUserOwnedExperience({
        userId: user.id,
        experienceId,
      });

      if (error) {
        console.error(error);
        setIsLoading(false);
        return;
      }

      setExperienceDetails(data);
      setIsLoading(false);
    },
    [
      user,
      experienceId,
      getUserOwnedExperience,
    ],
  );

  useEffect(() => {
    if (!user || !experienceId) {
      return;
    }
    fetchExperienceDetails();
  }, [user, experienceId]);

  return { experienceDetails, isLoading, refetch: fetchExperienceDetails };
};

export const useUserExperiences = (options = {}) => {
  const { user } = useSessionContext();
  const [isLoading, setIsLoading] = useState(true);
  const [experienceList, setExperienceList] = useState(null);
  const { enabled, excludeDefaultExperience } = options;

  const fetchExpList = useCallback(
    async () => {
      if (enabled === false) {
        return;
      }

      const { data, error } = excludeDefaultExperience
        ? await getUserOwnedExperiencesWithoutDefault({ userId: user.id })
        : await getUserOwnedExperiences({ userId: user.id });

      if (error) {
        console.error(error);
        setIsLoading(false);
        return;
      }

      setExperienceList(data);
      setIsLoading(false);
    },
    [
      user,
      enabled,
      getUserOwnedExperiences,
    ],
  );

  useEffect(() => {
    if (!user || enabled === false) {
      return;
    }

    fetchExpList();
  }, [user, enabled]);

  return { experienceList, isLoading, refetch: fetchExpList };
};

export const useExperiencesAccessCodes = (options = {}) => {
  const { user } = useSessionContext();
  const [isLoading, setIsLoading] = useState(true);
  const [accessCodesList, setAccessCodesList] = useState(null);
  const { enabled } = options;

  const fetchAccessCodesList = useCallback(
    async () => {
      if (enabled === false) {
        return;
      }

      const { data, error } = await getUserExpAccessCodes({ userId: user.id });

      if (error) {
        console.error(error);
        setIsLoading(false);
        return;
      }

      setAccessCodesList(data);
      setIsLoading(false);
    },
    [
      user,
      enabled,
    ],
  );

  useEffect(() => {
    if (!user || enabled === false) {
      return;
    }

    fetchAccessCodesList();
  }, [user, enabled]);

  return { accessCodesList, isLoading, refetch: fetchAccessCodesList };
};

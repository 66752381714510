import { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogBody,
  Typography,
} from '@material-tailwind/react';
import { useXp } from '../contexts/XpContext';
import { getExperienceDetails, getGeoXpConfig } from '../utils/helpers/supabase.helpers';
import { validateConfig } from '../utils/helpers/geoxp.helpers';
import { useMountEffect, usePageTitle } from '../hooks/toolkit';
import MapboxMap from '../Map/MapboxMap';
import Loading from '../layout/Loading';
import ManualSpot from '../components/ManualSpot';
import { useGeneralToast } from '../hooks/toast';
import PlayersCarousel from '../Map/PlayersCarousel';
import SinglePlayer from '../Map/SinglePlayer';

const SoundMap = ({ experienceId }) => {
  usePageTitle('Experience | Agami');

  const { key } = useLocation();
  const autostart = key !== 'default';

  const toast = useGeneralToast();
  const {
    loadConfig,
    config,
    hasManualSpots,
    active,
    start,
    destroy,
    playManually,
  } = useXp();

  const [loading, setLoading] = useState(true);
  const [startDialog, setStartDialog] = useState(!autostart);
  const [experienceName, setExperienceName] = useState(null);

  const getConfig = useCallback(async () => {
    setLoading(true);

    const { data: expDetails } = await getExperienceDetails({ experienceId });
    if (expDetails && expDetails[0]?.name) setExperienceName(expDetails[0]?.name);

    const { data, error } = await getGeoXpConfig({ experienceId });

    if (error) {
      console.error(error);
      return;
    }
    if (!data) return;

    const isValidConfig = validateConfig(data);
    if (!isValidConfig) {
      console.error('Invalid geoXp config options - cannot load');
      return;
    }

    loadConfig(data);
  }, [experienceId, loadConfig]);

  const playSpotManually = useCallback((code) => {
    const error = playManually(code);
    if (error) toast.error(`Cannot play manually, ${error}`);
  }, [start]);

  const handleStart = () => {
    setStartDialog(false);
    if (config) start();
  };

  useMountEffect(() => {
    getConfig();

    return () => {
      destroy();
    };
  });

  useEffect(() => {
    getConfig();
  }, [experienceId]);

  useEffect(() => {
    if (config) {
      if (autostart || !startDialog) start();
      setLoading(false);
    }
  }, [config, autostart, startDialog]);

  if (loading) return (<Loading />);

  return (
    <>
      <div className="h-full w-full z-0">

        <MapboxMap />

        {active?.length === 1 && (
          <SinglePlayer activeSpot={active[0]} />
        )}

        {active?.length > 1 && (
          <PlayersCarousel activeSpots={active} />
        )}

        {(active?.length === 0 && hasManualSpots) && (
          <ManualSpot onPlay={playSpotManually} />
        )}

      </div>

      <Dialog
        open={startDialog}
        handler={() => {
          if (startDialog && config) start();
          setStartDialog((s) => !s);
        }}
      >
        <DialogBody className="p-4 text-center flex flex-col gap-y-4 text-primary">
          <Typography variant="h3">
            Welcome
            {!!experienceName && ` to ${experienceName}`}
          </Typography>
          <Typography>Are you ready to start the experience?</Typography>
          <Button onClick={handleStart}>Start</Button>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default SoundMap;

import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner, Typography } from '@material-tailwind/react';
import UploadAgamiInterface from '../ViewUpload/UploadAgamiInterface';
import { getSpotTypes } from '../utils/helpers/supabase.helpers';
import { getSpot } from '../utils/helpers/user-spot.helpers';
import { useSessionContext } from '../contexts/SessionContext';
import { usePageTitle } from '../hooks/toolkit';
import { useBucketInfo } from '../hooks/supabase';
import GoBack from '../components/GoBack';

const DashboardAgamiDetail = () => {
  usePageTitle('Edit Agami Details | Agami Dashboard');

  const { spotId } = useParams();
  const { loading, user } = useSessionContext();

  const {
    loading: bucketLoading,
    uploadSizeLimit,
    allowedFileTypes,
  } = useBucketInfo();

  const [spotLoading, setSpotLoading] = useState(true);
  const [availableSpotTypes, setAvailableSpotTypes] = useState();
  const [spot, setSpot] = useState(null);

  const getAvailableSpotTypes = async () => {
    const { data } = await getSpotTypes();
    const types = data?.reduce((acc, curr) => ({
      ...acc,
      [curr.id]: curr.label,
    }), {});
    setAvailableSpotTypes(types);
  };

  const getExistingSpot = async () => {
    if (!spotId) return;
    const { spotData, error } = await getSpot({
      spotId,
    });

    if (error || !spotData) {
      setSpotLoading(false);
      return;
    }

    setSpot(spotData);
    setSpotLoading(false);
  };

  useEffect(() => {
    if (loading || !user?.id) {
      return;
    }

    getAvailableSpotTypes();
    getExistingSpot();
  }, [loading, user]);

  const isLoading = useMemo(
    () => loading || bucketLoading || spotLoading,
    [loading, bucketLoading, spotLoading],
  );

  return (
    <div className="w-full flex flex-col py-4">

      <div className="flex items-center justify-center gap-2 self-start">
        <GoBack />
        <Typography
          variant="lead"
          className="text-primary"
        >
          {spot?.name}
        </Typography>
      </div>

      {isLoading && (
        <div className="w-full my-10">
          <Spinner className="h-12 w-12 mx-auto" />
        </div>
      )}

      {!isLoading && !spot && (
        <div className="w-full my-10">
          <Typography>Agami not found</Typography>
        </div>
      )}

      {(
        !isLoading
        && availableSpotTypes
        && spot
      ) && (
      <UploadAgamiInterface
        uploadSizeLimit={uploadSizeLimit}
        allowedFileTypes={allowedFileTypes}
        availableSpotTypes={availableSpotTypes}
        spot={spot}
      />
      )}
    </div>
  );
};

export default DashboardAgamiDetail;

import React from 'react';
import PropTypes from 'prop-types';
import { PiInfoLight } from 'react-icons/pi';
import {
  Typography,
  Tooltip,
  Switch,
} from '@material-tailwind/react';
import { AgamiSpotPropType } from '../PropTypes';

const AgamiVisibilitySelector = ({ isPublic, setIsPublic, formData }) => (
  <div className="flex gap-4 justify-start items-center">
    <div className="flex gap-1 justify-start items-center">
      <Typography
        className="font-bold text-primary"
      >
        Visibility
      </Typography>
      <Tooltip
        content={(
          <div className="w-[300px]">
            <Typography
              variant="small"
              className="font-bold"
            >
              If selected, this spot will also be uploaded
              to the Agami Public experience.
              NOTE: manual agami cannot be public!
            </Typography>
          </div>
          )}
        placement="right"
        interactive
      >
        <span>
          <PiInfoLight className="text-primary" />
        </span>
      </Tooltip>
    </div>

    <Switch
      id="public_spot"
      label="Make also public"
      checked={isPublic}
      onChange={(e) => setIsPublic(e.currentTarget.checked)}
      disabled={formData.type === 2}
    />
  </div>
);

AgamiVisibilitySelector.propTypes = {
  isPublic: PropTypes.bool,
  setIsPublic: PropTypes.func,
  formData: AgamiSpotPropType,
};

export default AgamiVisibilitySelector;

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useParams } from 'react-router-dom';
import { useXp } from './XpContext';
import { getBoundsFromPositions } from '../utils/helpers/map.helpers';
import { ROUTE_STYLE_OPTS } from '../utils/constants/map.constants';

const ROUTE_OPACITY_TIMEOUT = 3000;
const FIT_BOUNDS_PADDING = 50;
const FIT_BOUNDS_ANIMATION_DURATION = 500;

const MapControlsContext = createContext({
  mapRef: null,
  route: [],
  resetRoute: () => { },
});

const useMapControls = () => useContext(MapControlsContext);

const MapControlsContextProvider = ({ children }) => {
  const { location, positions, manualMode } = useXp();

  const mapRef = useRef(null);

  const [dragging, setDragging] = useState(false);

  const [route, setRoute] = useState([]);
  const [routeOpacity, setRouteOpacity] = useState(ROUTE_STYLE_OPTS.opacity);
  const segmentOpacityTimeout = useRef(null);

  const { experienceId } = useParams();
  const [drawer, setDrawer] = useState(false);
  const [drawerForced, setDrawerForced] = useState(!experienceId);

  const fitMapBounds = useCallback(() => {
    if (
      !mapRef.current
      || !positions
      || !Array.isArray(positions)
      || positions.length === 0
    ) {
      return;
    }

    const map = mapRef.current;
    const mapBounds = getBoundsFromPositions(positions);
    map.fitBounds(mapBounds, {
      padding: FIT_BOUNDS_PADDING,
      duration: FIT_BOUNDS_ANIMATION_DURATION,
    });
  }, [mapRef.current, positions]);

  const resetRoute = useCallback(() => {
    setRoute([]);
  }, []);

  const setRouteSegment = useCallback((segment) => {
    setRouteOpacity(ROUTE_STYLE_OPTS.opacity);
    clearTimeout(segmentOpacityTimeout.current);
    segmentOpacityTimeout.current = setTimeout(
      () => setRouteOpacity(0),
      ROUTE_OPACITY_TIMEOUT,
    );

    setRoute((prev) => {
      // keep only last two
      const secondLastPath = prev[prev.length - 2];
      const lastPath = prev[prev.length - 1];
      return [
        secondLastPath,
        lastPath,
        [segment.longitude, segment.latitude],
      ];
    });
  }, [setRoute]);

  useEffect(() => {
    if (!location?.coords) return;
    setRouteSegment({
      longitude: location.coords.longitude,
      latitude: location.coords.latitude,
    });
  }, [location]);

  useEffect(
    () => resetRoute(),
    [manualMode],
  );

  const contextValue = useMemo(
    () => ({
      mapRef,
      route,
      setRouteSegment,
      routeOpacity,
      resetRoute,
      fitMapBounds,
      dragging,
      setDragging,
      drawer,
      setDrawer,
      drawerForced,
      setDrawerForced,
    }),
    [
      mapRef,
      route,
      routeOpacity,
      resetRoute,
      fitMapBounds,
      dragging,
      drawer,
      drawerForced,
    ],
  );

  return (
    <MapControlsContext.Provider value={contextValue}>
      {children}
    </MapControlsContext.Provider>
  );
};

export { useMapControls, MapControlsContextProvider };

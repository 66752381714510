import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip, Typography,
} from '@material-tailwind/react';
import { Source, Layer, Marker } from 'react-map-gl';
import {
  ACTIVE_RADIUS_CIRCLE_STYLE_OPTS,
  ACTIVE_DEADBAND_CIRCLE_STYLE_OPTS,
  RADIUS_CIRCLE_STYLE_OPTS,
  DEADBAND_CIRCLE_STYLE_OPTS,
} from '../utils/constants/map.constants';
import { metersToPixelsAtMaxZoom } from '../utils/helpers/map.helpers';
import icon from '../assets/svg/spot-marker.svg';
import { GeoXpPositionPropType } from '../PropTypes';

const XpSpotLayer = ({ position, activePosIds }) => {
  const center = [position.lon, position.lat];
  const isActive = activePosIds?.includes(position.id);

  const feature = {
    type: 'Feature',
    id: `${position.id}_feature`,
    geometry: {
      type: 'Point',
      coordinates: center,
    },
  };

  const radiusCircleStyle = useMemo(
    () => (isActive ? ACTIVE_RADIUS_CIRCLE_STYLE_OPTS : RADIUS_CIRCLE_STYLE_OPTS),
    [isActive],
  );

  const deadbandCircleStyle = useMemo(
    () => (isActive ? ACTIVE_DEADBAND_CIRCLE_STYLE_OPTS : DEADBAND_CIRCLE_STYLE_OPTS),
    [isActive],
  );

  const radiusCircleSize = metersToPixelsAtMaxZoom(
    position.radius,
    position.lat,
  );

  const deadbandCircleSize = metersToPixelsAtMaxZoom(
    (position.radius + position.deadband),
    position.lat,
  );

  const radiusLayerStyle = {
    id: `${position.id}_radius_circle`,
    type: 'circle',
    paint: {
      'circle-radius': {
        stops: [
          [0, 0],
          [20, radiusCircleSize],
        ],
        base: 2,
      },
      'circle-stroke-color': radiusCircleStyle.color,
      'circle-stroke-opacity': radiusCircleStyle.opacity,
      'circle-stroke-width': radiusCircleStyle.weight,
      'circle-stroke-color-transition': {
        duration: 1000,
        delay: 0,
      },
      'circle-color': radiusCircleStyle.fillColor,
      'circle-opacity': radiusCircleStyle.fillOpacity,
      'circle-color-transition': {
        duration: 1000,
        delay: 0,
      },
    },
  };

  const deadbandLayerStyle = {
    id: `${position.id}_deadband_circle`,
    type: 'circle',
    paint: {
      'circle-radius': {
        stops: [
          [0, 0],
          [20, deadbandCircleSize],
        ],
        base: 2,
      },
      'circle-stroke-color': deadbandCircleStyle.color,
      'circle-stroke-opacity': deadbandCircleStyle.opacity,
      'circle-stroke-width': deadbandCircleStyle.weight,
      'circle-stroke-color-transition': {
        duration: 1000,
        delay: 0,
      },
      'circle-color': deadbandCircleStyle.fillColor,
      'circle-opacity': deadbandCircleStyle.fillOpacity,
      'circle-color-transition': {
        duration: 1000,
        delay: 0,
      },
    },
  };

  return (
    <>
      <Source
        id={`${position.id}_source`}
        type="geojson"
        data={feature}
      >
        <Layer {...radiusLayerStyle} />
        <Layer {...deadbandLayerStyle} />
      </Source>

      <Marker
        longitude={position.lon}
        latitude={position.lat}
        anchor="center"
      >
        <Tooltip content={(
          <div className="w-[300px]">
            <Typography className="font-bold">
              {position.label}
            </Typography>
            <Typography variant="small">
              {position.description}
            </Typography>
          </div>
        )}
        >
          <img alt="agami" src={icon} />
        </Tooltip>
      </Marker>
    </>
  );
};

XpSpotLayer.propTypes = {
  position: GeoXpPositionPropType,
  activePosIds: PropTypes.arrayOf(PropTypes.string),
};

export default XpSpotLayer;

import { Typography } from '@material-tailwind/react';

const Avatar = ({
  size = 'md',
  name,
  surname,
  image,
  interactive,
}) => {
  const dimensions = {
    img: '',
    font: '',
  };
  switch (size) {
    case 'sm': {
      dimensions.img = 'w-7 h-7';
      dimensions.font = 'small';
      break;
    }
    case 'md': {
      dimensions.img = 'w-10 h-10';
      dimensions.font = 'paragraph';
      break;
    }
    case 'lg': {
      dimensions.img = 'w-16 h-16';
      dimensions.font = 'lead';
      break;
    }
    default: {
      dimensions.img = 'w-10 h-10';
      dimensions.font = 'paragraph';
      break;
    }
  }

  return (
    <div
      className={`
        rounded-full overflow-hidden
        ${dimensions.img}
        bg-white-yellow text-primary
        ${interactive && ' opacity-90 hover:opacity-100 cursor-pointer'}
      `}
    >
      {image ? (
        <div
          className="w-full h-full"
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        />
      ) : (
        <div className="flex w-full h-full justify-center items-center">
          <Typography variant={dimensions.font} className="font-bold">{name?.charAt(0)}</Typography>
          <Typography variant={dimensions.font} className="font-bold">{surname?.charAt(0)}</Typography>
        </div>
      )}
    </div>
  );
};

export default Avatar;

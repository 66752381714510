export const GEOXP_AUDIO_BUCKET = 'geoxp_audio';

export const GEOXP_SPOTS_TABLE = 'geoxp_spots';

export const EXPERIENCE_CONTENT_TABLE = 'experience_content_spots';

export const AGAMI_GLOBAL_EXPERIENCE_ID = '679ec009-b360-40c3-bf21-ed49e4a7789c';

export const EXPERIENCES_TABLE = 'experiences';

export const USER_EXPERIENCES_TABLE = 'user_experiences';

import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { LinkPropType } from '../PropTypes';

const Tabs = ({ links }) => {
  const { pathname } = useLocation();
  return (
    <div className="w-full bg-primary flex gap-4 justify-around">
      {links.map((link) => {
        const isActive = (link.activeLinkCondition || pathname === link.to);
        const linkColor = isActive
          ? 'text-accent'
          : 'text-white-yellow';

        return (
          <Link
            className={`hover:opacity-50 ${linkColor}`}
            key={`${link.to}`}
            to={link.to}
          >
            <div
              className={
                `py-4 flex flex-col items-center 
              justify-center gap-2 border-accent ${isActive && 'border-b-4'}`
              }
            >
              {link.Icon ? <link.Icon className="w-6 h-6" /> : null}
              <span>{link.label}</span>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

Tabs.propTypes = {
  links: PropTypes.arrayOf(LinkPropType),
};

export default Tabs;

import { useEffect, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { SCREEN_BREAKPOINTS } from '../utils/helpers/ui.helpers';

/*
 * Exploits the useEffect hook to define a custom hook that
 * executes a function only once, after the inital render!
 */
// eslint-disable-next-line
export const useMountEffect = (funct) => useEffect(funct, []);

/*
 * Keeps the previous value of a state. Usage example:
 * const [count, setCount] = useState(0);
 * const prevCount = usePrevious(count);
 * console.log(`Now: ${count}, before: ${prevCount}`);
 */
export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => { ref.current = value; }, [value]);
  return ref.current;
};

export const useQueryParams = () => new URLSearchParams(window.location.search);

/**
 * useState and useRef combined together, to update state and re-render component,
 * but use always the last updated value
 * @link https://github.com/Aminadav/react-useStateRef#readme
 */
export const useStateRef = (defaultValue) => {
  const [state, setState] = useState(defaultValue);
  const ref = useRef(defaultValue);
  ref.current = state;
  return [
    state,
    (newValue) => {
      ref.current = newValue;
      return setState(newValue);
    },
    ref,
  ];
};

export const useOnInit = () => {
  const isMountRef = useRef(true);

  useEffect(() => {
    isMountRef.current = false;
  }, []);

  return isMountRef.current;
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export const usePageTitle = (title) => {
  useMountEffect(() => {
    document.title = title ?? 'Agami';
  });
};

export const useDeepCompareEffect = (val) => {
  const [isChanged, setIsChanged] = useState(false);
  const prev = usePrevious(val);
  useEffect(
    () => {
      if (prev) {
        const changed = !isEqual(val, prev);
        setIsChanged(changed);
      } else {
        setIsChanged(false);
      }
      return () => setIsChanged(false);
    },
    [val],
  );
  return [isChanged, setIsChanged];
};

export const useDeepCompareObjectsEffect = (obj1, obj2) => {
  const [isChanged, setIsChanged] = useState(false);
  useEffect(
    () => {
      if (obj1 && obj2) {
        const changed = !isEqual(obj1, obj2);
        setIsChanged(changed);
      } else {
        setIsChanged(false);
      }
      return () => setIsChanged(false);
    },
    [obj1, obj2],
  );
  return [isChanged, setIsChanged];
};

export const useScreenBreakpoint = () => {
  const { width } = useWindowSize();

  if (width < SCREEN_BREAKPOINTS.md) {
    return 'sm';
  }

  if (width < SCREEN_BREAKPOINTS.lg) {
    return 'md';
  }

  if (width < SCREEN_BREAKPOINTS.xl) {
    return 'lg';
  }

  if (width < SCREEN_BREAKPOINTS['2xl']) {
    return 'xl';
  }

  return '2xl';
};

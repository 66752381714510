const typography = {
  valid: {
    variants: [
      'h1',
      'h3',
      'h5',
      'lead',
      'paragraph',
      'small',
    ],
  },
  styles: {
    variants: {
      h1: {
        fontFamily: 'font-neusa',
        fontSize: 'text-5xl',
        lineHeight: 'leading-none',
      },
      h3: {
        fontFamily: 'font-neusa',
        fontSize: 'text-2xl',
        lineHeight: 'leading-none',
      },
      h5: {
        fontSize: 'text-[1.25rem]',
      },
      lead: {
        fontFamily: 'font-neusa',
        fontSize: 'text-4xl',
        lineHeight: 'leading-none',
      },
      paragraph: {
        fontSize: 'text-base',
      },
      small: {
        fontSize: 'text-[0.685rem]',
      },
    },
  },
};

const button = {
  defaultProps: {
    variant: 'filled',
    size: 'md',
    color: 'yellow',
    fullWidth: false,
    ripple: true,
    className: 'focus:ring-0',
  },
  valid: {
    colors: [
      'yellow',
      'primary',
    ],
  },
  styles: {
    base: {
      initial: {
        textTransform: '',
      },
    },
    sizes: {
      md: {
        fontSize: 'text-[0.812rem]',
        py: 'py-3',
        px: 'px-10',
        borderRadius: 'rounded-lg',
      },
      sm: {
        fontSize: 'text-[0.812rem]',
        py: 'py-1',
        px: 'px-4',
        borderRadius: 'rounded-lg',
      },
    },
    variants: {
      filled: {
        primary: {
          backgroud: 'bg-primary',
          color: 'text-white',
          shadow: 'shadow-md shadow-primary-500/10',
          hover: 'hover:shadow-lg hover:shadow-primary-500/20',
          focus: 'focus:opacity-[0.85] focus:shadow-none',
          active: 'active:opacity-[0.85] active:shadow-none',
        },
      },
      outilined: {
        primary: {
          border: 'border border-primary',
          color: 'text-primary',
          hover: 'hover:opacity-75',
          focus: 'focus:ring-0',
          active: 'active:opacity-[0.85]',
        },
      },
      text: {
        primary: {
          color: 'text-primary opacity-90',
          hover: 'hover:opacity-100',
          active: 'active:opacity-100',
        },
      },
    },
  },
};

const iconButton = {
  valid: {
    colors: [
      'primary',
    ],
  },
  styles: {
    variants: {
      filled: {
        primary: {
          backgroud: 'bg-primary',
          color: 'text-white-yellow',
          shadow: 'shadow-md shadow-primary-500/10',
          hover: 'hover:shadow-lg hover:shadow-primary-500/20',
          focus: 'focus:opacity-[0.85] focus:shadow-none',
          active: 'active:opacity-[0.85] active:shadow-none',
        },
      },
      gradient: {
        primary: {
          backgroud: 'bg-primary',
          color: 'text-white-yellow',
          shadow: 'shadow-md shadow-primary-500/10',
          hover: 'hover:shadow-lg hover:shadow-primary-500/20',
          focus: 'focus:opacity-[0.85] focus:shadow-none',
          active: 'active:opacity-[0.85] active:shadow-none',
        },
      },
      outlined: {
        primary: {
          border: 'border border-primary',
          color: 'text-primary',
          hover: 'hover:shadow-lg hover:shadow-primary-500/20',
          focus: 'focus:opacity-[0.85] focus:shadow-none',
          active: 'active:opacity-[0.85] active:shadow-none',
        },
      },
      text: {
        primary: {
          color: 'text-white-yellow',
          hover: 'hover:shadow-lg hover:shadow-primary-500/20',
          active: 'active:opacity-[0.85] active:shadow-none',
        },
      },
    },
  },
};

const input = {
  defaultProps: {
    variant: 'outlined',
    size: 'md',
    color: 'dark-bg',
  },
  styles: {
    base: {
      input: {
        color: 'inherit',
        borderWidth: 'placeholder-shown:border',
        borderColor: 'border-gray-500 placeholder-shown:border-gray-500',
      },
    },
    variants: {
      outlined: {
        base: {
          input: {
            color: 'inherit',
            borderWidth: 'placeholder-shown:border',
            borderColor: 'border-gray-500 placeholder-shown:border-gray-500',
          },
        },
      },
    },
  },
};

const card = {
  styles: {
    base: {
      initial: {
        borderRadius: 'rounded-md',
        display: 'flex',
        flexDirection: 'flex-row items-center',
      },
      shadow: {
        boxShadow: 'shadow-md',
      },
    },
  },
};
const cardHeader = {
  defaultProps: {
    floated: false,
    className: 'flex-shrink-0 w-[80px] h-[80px] rounded-full bg-white-yellow',
  },
  styles: {
    base: {
      initial: {
        mt: 'mt-0',
        mx: 'mx-0',
      },
    },
  },
};

const cardBody = {
  defaultProps: {
    className: 'p-4 h-fit flex-1',
  },
};

const progress = {
  valid: {
    colors: [
      'primary',
    ],
  },
  styles: {
    variants: {
      filled: {
        primary: {
          backgroud: 'bg-primary',
          color: 'text-white',
        },
      },
      gradient: {
        primary: {
          backgroud: 'bg-gradient-to-tr from-primary-600 to-primary-400',
          color: 'text-white',
        },
      },
    },
  },
};

const switchTheme = {
  defaultProps: {
    color: 'primary',
  },
  valid: {
    colors: [
      'primary',
      'accent',
    ],
  },
  styles: {
    colors: {
      primary: {
        input: 'checked:bg-primary-500',
        circle: 'peer-checked:border-primary-500',
        before: 'peer-checked:before:bg-primary-500',
      },
      accent: {
        input: 'checked:bg-accent-500',
        circle: 'peer-checked:border-accent-500',
        before: 'peer-checked:before:bg-accent-500',
      },
    },
  },
};

const radio = {
  defaultProps: {
    color: 'primary',
  },
  valid: {
    colors: [
      'primary',
      'accent',
    ],
  },
  styles: {
    colors: {
      primary: {
        color: 'text-primary-500',
        border: 'checked:border-primary-500',
        before: 'checked:before:bg-primary-500',
      },
      accent: {
        color: 'text-accent-500',
        border: 'checked:border-accent-500',
        before: 'checked:before:bg-accent-500',
      },
    },
  },
};

const spinner = {
  defaultProps: {
    color: 'primary',
  },
  valid: {
    colors: [
      'primary',
      'accent',
    ],
  },
  styles: {
    colors: {
      primary: {
        color: 'text-primary-500',
      },
      accent: {
        color: 'text-accent-500',
      },
    },
  },
};

const chip = {
  defaultProps: {
    color: 'primary',
  },
  valid: {
    colors: [
      'primary',
      'accent',
    ],
  },
  styles: {
    variants: {
      filled: {
        primary: {
          backgroud: 'bg-primary-500',
          color: 'text-white',
        },
        accent: {
          backgroud: 'bg-accent-500',
          color: 'text-primary',
        },
      },
      gradient: {
        primary: {
          backgroud: 'bg-gradient-to-tr from-primary-700 to-primary-300',
          color: 'text-white',
        },
        accent: {
          backgroud: 'bg-gradient-to-tr from-accent-600 to-accent-400',
          color: 'text-primary',
        },

      },
      outlined: {
        primary: {
          border: 'border border-primary-500',
          color: 'text-primary-700',
        },
        accent: {
          border: 'border border-accent-500',
          color: 'text-accent-700',
        },
      },
      ghost: {
        primary: {
          backgroud: 'bg-primary-500/20',
          color: 'text-primary-900',
        },
        accent: {
          backgroud: 'bg-accent-500/20',
          color: 'text-accent-800',
        },
      },
    },
  },
};

const dialog = {
  styles: {
    base: {
      backdrop: {
        backgroundColor: 'bg-primary',
        backgroundOpacity: 'bg-opacity-70',
        backdropFilter: 'none',
      },
      container: {
        fontFamily: 'font-neusa',
      },
    },
  },
};

const dialogHeader = {
  styles: {
    base: {
      fontFamily: 'font-neusa',
    },
  },
};

const dialogBody = {
  styles: {
    base: {
      initial: {
        fontFamily: 'font-neusa',
      },
    },
  },
};

const dialogFooter = {
  styles: {
    base: {
      fontFamily: 'font-neusa',
    },
  },
};

const checkbox = {
  defaultProps: {
    color: 'primary',
  },
  valid: {
    colors: [
      'primary',
      'accent',
    ],
  },
  styles: {
    colors: {
      primary: {
        background: 'checked:bg-primary-500',
        border: 'checked:border-primary-500',
        before: 'checked:before:bg-primary-500',
      },
      accent: {
        background: 'checked:bg-accent-500',
        border: 'checked:border-accent-500',
        before: 'checked:before:bg-accent-500',
      },
    },
  },
};

export const customTheme = {
  typography,
  button,
  iconButton,
  input,
  card,
  cardHeader,
  cardBody,
  switch: switchTheme,
  progress,
  radio,
  spinner,
  chip,
  dialog,
  dialogHeader,
  dialogBody,
  dialogFooter,
  checkbox,
};

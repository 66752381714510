import React from 'react';
import { PiSpeakerX } from 'react-icons/pi';
import { Alert, Typography } from '@material-tailwind/react';
import ReactAudioPlayer from 'react-audio-player';
import { AgamiSpotDetailPropType } from '../PropTypes';

const AudioFilePreview = ({ spot }) => (
  <div
    className="w-full rounded-lg p-8 bg-primary-200"
  >
    <Typography className="text-white font-bold">
      {spot.audio_filename ?? spot.audio_content_path}
    </Typography>
    {spot.audioUrl
      ? (
        <ReactAudioPlayer
          className="w-[130%] ml-[-15%] h-6 mt-2 scale-75"
          src={spot.audioUrl}
          controls
        />
      ) : (
        <Alert
          size="sm"
          color="amber"
          className="w-full md:w-1/2 py-2 px-4 mt-2 text-xs"
          icon={<PiSpeakerX className="w-4 h-4" />}
        >
          We could&apos;t find this audio file
        </Alert>
      )}
  </div>
);

AudioFilePreview.propTypes = {
  spot: AgamiSpotDetailPropType,
};

export default AudioFilePreview;

import React from 'react';
import { MdArrowBack } from 'react-icons/md';
import { IconButton } from '@material-tailwind/react';
import { useGoBack } from '../hooks/router';

const GoBack = () => {
  const goBack = useGoBack();
  return (
    <IconButton
      variant="text"
      className="text-primary"
      onClick={goBack}
    >
      <MdArrowBack className="w-6 h-6" />
    </IconButton>
  );
};

export default GoBack;

import { theme } from '../../tailwind.config';

const { colors } = theme.extend;
const PRIMARY = colors.primary;
const ACCENT = colors.accent;

export const TUTORIAL_STYLE = {
  options: {
    arrowColor: '#fff',
    backgroundColor: '#fff',
    beaconSize: 36,
    overlayColor: 'rgba(0, 0, 0, 0.5)',
    primaryColor: ACCENT.DEFAULT,
    spotlightShadow: ACCENT.DEFAULT,
    textColor: PRIMARY.DEFAULT,
    width: undefined,
    zIndex: 100,
  },
  buttonNext: {
    color: PRIMARY.DEFAULT,
  },
  buttonBack: {
    color: PRIMARY.DEFAULT,
  },
  buttonSkip: {
    color: PRIMARY.DEFAULT,
  },
};
